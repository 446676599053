import { CountDatas } from '../Functions/CountDatas.js';
import { SortASC } from '../Functions/SortASC.js';
import { GetIconCode } from '../Functions/GetIconCode.js';
import { GetStringTotal } from '../Functions/GetStringTotal.js';

export const BlockCollection = (props) => {
    const count_univers = props.att ? CountDatas('univers', props.att) : [];
    const count_category = props.att ? CountDatas('category', props.att) : [];
    const count_origin = props.att ? CountDatas('origin', props.att) : [];
    const count_character = props.cha ? CountDatas('character', props.cha) : [];
    const datas = props.datas ? SortASC(props.datas) : [];
    const total = props.datas ? props.datas.length : 0;
    switch (props.label) {
        case 'home':
        case 'park':
            return (
                <div className="list">
                    <div className="total">{`${total} ${GetStringTotal('univers', total)}`}</div>
                    {datas.map((el, i) => {
                        let tt_univers = count_univers[el.uslug];
                        let str_univers = GetStringTotal('attraction', tt_univers);
                        return (
                            <a className="link" href={el.uroute} alt={el.uname} title={el.uname} key={`i-${i}`}>
                                <div className="name">
                                    <div>{el.uname}</div>
                                    <div className="note">{`${tt_univers} ${str_univers}`}</div>
                                </div>
                            </a>
                        )
                    })}
                </div>
            )
        case 'univers':
        case 'attractions':
            return (
                <div className="list">
                    <div className="total">{`${total} ${GetStringTotal('attraction', total)}`}</div>
                    {datas.map((el, i) => {
                        return (
                            <a className="link" href={el.aroute} alt={el.aname} title={el.aname} key={`i-${i}`}>
                                <div className="code"><i className={GetIconCode(el.ccode)}></i></div>
                                <div className="name">{el.aname}</div>
                            </a>
                        )
                    })}
                </div>
            )
        case 'categories':
            return (
                <div className="list">
                    <div className="total">{`${total} ${GetStringTotal('catégorie', total)}`}</div>
                    {datas.map((el, i) => {
                        let tt_category = count_category[el.cslug];
                        let str_category = GetStringTotal('attraction', tt_category);
                        return (
                            <a className="link" href={el.croute} alt={el.cname} title={el.cname} key={`i-${i}`}>
                                <div className="code" title={el.cname}><i className={GetIconCode(el.ccode)}></i></div>
                                <div className="name">
                                    <div>{el.cname}</div>
                                    <div className="note">{`${tt_category} ${str_category}`}</div>
                                </div>
                            </a>
                        )
                    })}
                </div>
            )
        case 'origins':
            return (
                <div className="list">
                    <div className="total">{`${total} ${GetStringTotal('origine', total)}`}</div>
                    {datas.map((el, i) => {
                        let tt_attraction = count_origin[el.oslug];
                        let tt_character = count_character[el.oslug];
                        let str_attraction = GetStringTotal('attraction', tt_attraction);
                        let str_character = GetStringTotal('personnage', tt_character);
                        return (
                            <a className="link" href={el.oroute} alt={el.oname} title={el.oname} key={`i-${i}`}>
                                <div className="date">{`${el.ocreated.split('-')[0]}`}</div>
                                <div className="name">
                                    <div>{el.oname}</div>
                                    <div className="note">{`${tt_attraction} ${str_attraction}`}</div>
                                    <div className="note">{`${tt_character} ${str_character}`}</div>
                                </div>
                            </a>
                        )
                    })}
                </div>
            )
        default:
            return (
                <></>
            )
    }
}