import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../../../Routes/HostBackend.js';
import { Slugify } from '../../Functions/Slugify.js';
import { NameToArray } from '../../Functions/NameToArray.js';

export const SelectParent = ({ type, theme, value, onSelect, onDatas }) => {
    const hosting = HostBackend();
    const [chooserParent, setChooserParent] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            if (theme > 0) {
                const datasParent = await fetch(`${hosting}/admin/Upload/attractions?id=${theme}`);
                const chooserParent = await datasParent.json();
                setChooserParent(chooserParent);
            }
        };
        fetchData();
    }, [hosting, theme]);
    const sendData = (id) => {
        let parentChooser = NameToArray(chooserParent);
        return Slugify(parentChooser[id]);
    }
    if (type.match(/attractions/)) {
        return (
            <div className="row">
                <label className="label" htmlFor="theme">Theme</label>
                <select className="select" id="theme" name="theme" value={value} onChange={(e) => {
                    onSelect(e.target.value);
                    onDatas(sendData(e.target.value));
                }}>
                    <option value="0">-- choose option --</option>
                    {chooserParent.map((el, i) => {
                        return (<option key={`i-${i}`} value={i + 1}>{el.name}</option>)
                    })}
                </select>
            </div>
        );
    } else {
        return (<></>);
    }
}