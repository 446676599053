import { ButtonDelete } from "../Form/Buttons/ButtonDelete.js";
import { ButtonUpdate } from "../Form/Buttons/ButtonUpdate.js";

export const Attributes = (props) => {
    const entity = props.entity;
    const datas = props.datas;
    switch (entity) {
        case 'author':
        case 'evacuation':
        case 'handicap':
        case 'height':
        case 'interest':
        case 'origintype':
        case 'photopass':
        case 'pmr':
        case 'premieraccess':
        case 'public':
        case 'sensory':
        case 'serie':
        case 'singlerider':
        case 'sort':
            return (
                datas.map((el, i) => {
                    return (
                        <div key={`i-${i}`} className="rows">
                            <div className="block-rows">
                                <div className="id">{i + 1}</div>
                                <div className="item">{el.name} <span>({el.id})</span></div>
                            </div>
                            <div className="block-buttons">
                                <ButtonUpdate entity={entity} id={el.id} />
                                <ButtonDelete entity={entity} id={el.id} />
                            </div>
                        </div>
                    )
                }));
        default:
            return (
                <></>
            )
    }

}