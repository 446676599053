import { useState } from 'react';
import { GetAllEntities } from '../Functions/GetAllEntities.js';
import { GetUpperCase } from '../../Public/Functions/GetUpperCase.js';
/* document */
import { ButtonInsert } from '../Form/Buttons/ButtonInsert.js';
import { ButtonLogout } from '../Form/Buttons/ButtonLogout.js';
import { Selector } from '../Collection/Selector.js';
import { Finder } from './Finder.js';

export const Navigation = (props) => {
    const entity = props.entity;
    const chooser = props.chooser;
    const hash = sessionStorage.getItem('token');
    const entities = GetAllEntities()[0];
    const attributes = GetAllEntities()[1];
    const describes = GetAllEntities()[2];
    const [show, setShow] = useState('hide');
    if (hash) {
        return (
            <div className="admin-nav">
                <div className="buttons">
                    <div className="slider" onClick={() => { show === 'show' ? setShow('hide') : setShow('show'); }}>
                        <i className="fa fa-bars"></i>
                    </div>
                    <div className={`nav ${show}`}>
                        <div className="nav-parent">
                            {entities.map((name, i) => {
                                return (
                                    <a key={`e${i}`} className="link" href={`/admin/entity/collection/${name}`}>{name}</a>
                                )
                            })}
                        </div>
                        <div className="nav-parent">
                            {attributes.map((name, i) => {
                                return (
                                    <a key={`a${i}`} className="link" href={`/admin/entity/collection/${name}`}>{name}</a>
                                )
                            })}
                        </div>
                        <div className="nav-parent">
                            {describes.map((name, i) => {
                                let table = name.split('describe')[1];
                                let entity = `describe_${table}`;
                                return (
                                    <a key={`a${i}`} className="link" href={`/admin/entity/collection/${entity}`}>{entity}</a>
                                )
                            })}
                        </div>
                        <div className="nav-parent">
                            <a className="link" href={`/admin/upload/image`}>Upload</a>
                            <a className="link" href={`/admin/database/backup`}>Backup</a>
                            <a className="link" href={`/admin/database/collection`}>Collection</a>
                            <a className="link" href={`/admin/database/generate`}>Generate</a>
                        </div>
                    </div>
                    <ButtonInsert />
                    <div className="legend-entity" id="entity">{GetUpperCase(entity)}</div>
                </div>
                <div className="buttons">
                    <Finder />
                    <Selector entity={entity} chooser={chooser} />
                    <ButtonLogout />
                </div>
            </div >
        )
    } else {
        return (
            <></>
        )
    }

}