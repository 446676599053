import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
/* datas */
import { HostBackend } from '../HostBackend.js';
/* document */
import { Home } from '../../Pages/Public/Home.js';
import { NotFound } from '../../Pages/Public/NotFound.js';

export const RouteWebsocket = (props) => {
    const navParks = props.navParks;
    const hosting = HostBackend();
    const [navAttractions, setNavAttractions] = useState([]);
    const [navCaracters, setNavCaracters] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const dataNavAttractions = await fetch(`${hosting}/nav/attractions`);
            const navAttractions = await dataNavAttractions.json();
            setNavAttractions(navAttractions);
            const dataNavCaracters = await fetch(`${hosting}/nav/characters`);
            const navCaracters = await dataNavCaracters.json();
            setNavCaracters(navCaracters);
        };
        fetchData();
    }, [hosting]);
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/ws" element={<Home navParks={navParks} att={navAttractions} car={navCaracters} />} />
                <Route path="*" element={<NotFound navParks={navParks} />} />
            </Routes>
        </BrowserRouter>
    )
}