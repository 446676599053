export const FilterExperiments = (datas, id) => {
    const collection = [];
    const map = new Map();
    for (const el of datas) {
        if (!map.has(el.name) && el.id_serie === id) {
            map.set(el.name, true);
            collection.push({
                id: el.id,
                serie: el.id_serie-1,
                sname: el.sname,
                name: el.name,
                slug: el.slug,
                number: el.number,
                route: el.route,
            });
        }
    }
    return collection;
}