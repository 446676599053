import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../../../Routes/HostBackend.js';
/* document */
import { Text } from '../../Form/Inputs/Text.js';
import { Select } from '../../Form/Inputs/Select.js';
import { Hidden } from '../../Form/Inputs/Hidden.js';

export const Experiment = (props) => {
    const hosting = HostBackend();
    const [typeChooser, setTypeChooser] = useState([]);
    const [authorChooser, setAuthorChooser] = useState([]);
    const [describeChooser, setDescribeChooser] = useState([]);
    const id = (props.el) ? props.el.id : '';
    const id_serie = (props.el) ? props.el.id_serie : '';
    const id_author = (props.el) ? props.el.id_author : '';
    const id_description = (props.el) ? props.el.id_description : '';
    const name = (props.el) ? props.el.name : '';
    const slug = (props.el) ? props.el.slug : '';
    const number = (props.el) ? props.el.number : '';
    const route = (props.el) ? props.el.route : '';
    useEffect(() => {
        const fetchData = async () => {
            const datasSerie = await fetch(`${hosting}/admin/collection/serie`);
            const typeChooser = await datasSerie.json();
            setTypeChooser(typeChooser);
            const datasAuthor = await fetch(`${hosting}/admin/collection/author`);
            const authorChooser = await datasAuthor.json();
            setAuthorChooser(authorChooser);
            const datasDescribes = await fetch(`${hosting}/admin/collection/describe_experiment`);
            const describeChooser = await datasDescribes.json();
            setDescribeChooser(describeChooser);
        };
        fetchData();
    }, [hosting]);
    return (
        <>
            <Select name="id_serie" chooser={typeChooser} value={id_serie} />
            <Select name="id_author" chooser={authorChooser} value={id_author} />
            <Select name="id_description" chooser={describeChooser} value={id_description} />
            <Text name="name" value={name} />
            <Text name="slug" value={slug} />
            <Text name="number" value={number} />
            <Text name="route" value={route} />
            <Hidden name="id" value={id} />
        </>
    )
}