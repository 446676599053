import { Navigation } from '../../Components/Admin/Document/Navigation.js';
import { Footer } from '../../Components/Public/Document/Footer.js';
import { BackTop } from '../../Components/Public/Document/BackTop.js';
import { FormInsert } from '../../Components/Admin/Form/FormInsert.js';
import { AutoComplete } from '../../Components/Admin/Functions/AutoComplete.js';

export const Insert = (props) => {
    const entity = props.entity;
    return (
        <div className="insert">
            <Navigation entity={entity} chooser={[]} />
            <div className="admin-form">
                <FormInsert entity={entity} />
                <a className="back" href={`/admin/entity/collection/${entity}`} alt="back" title="back">
                    <i className="fa fa-reply"></i>
                </a>
            </div>
            <BackTop />
            <Footer />
            <script>{AutoComplete()}</script>
        </div>
    )
}