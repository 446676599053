import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
import { LogoImgAttraction } from '../../Files/Logos/LogoImgAttraction.js';
/* document */
import { Navigation } from '../../Components/Public/Document/Navigation.js';
import { Header } from '../../Components/Public/Document/Header.js';
import { Footer } from '../../Components/Public/Document/Footer.js';
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { InfosAttraction} from '../../Components/Public/Blocks/InfosAttraction.js';
import { Carrousel } from '../../Components/Public/Carrousel/Carrousel.js';
import { BlockTitle } from '../../Components/Public/Blocks/BlockTitle.js';
import { BlockOutlink } from '../../Components/Public/Blocks/BlockOutlink.js';
import { BackTop} from '../../Components/Public/Document/BackTop.js';
import { ScrollTop } from '../../Components/Public/Functions/ScrollTop.js';
import { AnimCarrousel } from '../../Components/Public/Functions/AnimCarrousel.js';

export const Attraction = (props) => {
    const navParks = props.navParks;
    const hosting = HostBackend();
    const id = parseInt(props.id);
    const [attraction, setAttraction] = useState([]);
    useEffect(() => {
        const fetchtheData = async () => {
            const dataAttraction = await fetch(`${hosting}/attraction?id=${id}`);
            const attraction = await dataAttraction.json();
            setAttraction(attraction);
        };
        fetchtheData();
        ScrollTop();
        AnimCarrousel();
    }, [hosting, id]);
    let items = {};
    const collection = [];
    let total = 0;
    for (let i = 0; i < attraction.length; i++) {
        collection.push({
            name: attraction[i].aname,
            slug: attraction[i].aslug,
            href: attraction[i].aroute,
            images: LogoImgAttraction('img', attraction[i].aslug),
        });
        total = LogoImgAttraction('img', attraction[i].aslug).length;
    }
    return (
        <div className="attraction">
            <Navigation navParks={navParks} />
            <Header />
            <Carrousel label="attraction" datas={collection} total={total} />
            <BreadCrumb />
            <section className="infos">
                {attraction.map((item, i) => {
                    items = item;
                    return (
                        <div className="bloc-infos" key={`b-${i}`}>
                            <BlockLogo label="attraction" item={item} />
                            <InfosAttraction datas={attraction} />
                        </div>
                    )
                })}
                <div className="description">
                    <BlockTitle label="attraction" item={items} />
                    <p>{items.description}</p>
                    <BlockOutlink item={items} />
                </div>
            </section>
            <BackTop />
            <Footer />
        </div>
    )
}