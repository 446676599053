import { useParams } from 'react-router-dom';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
import { CleanAdminSession } from '../../Components/Admin/Functions/CleanAdminSession.js';
/* document */
import { Navigation } from '../../Components/Public/Document/Navigation.js';
import { Email } from '../../Components/Admin/Form/Inputs/Email.js';
import { Password } from '../../Components/Admin/Form/Inputs/Password.js';
import { BlockLink } from '../../Components/Public/Blocks/BlockLink.js';
import { Footer } from '../../Components/Public/Document/Footer.js';

export const Login = () => {
    let { hash } = useParams();
    let { id } = useParams();
    if (id && hash) {
        sessionStorage.setItem('id', id);
        sessionStorage.setItem('token', hash);
        return (
            <div className="login">
                <div className="admin-form">
                    <div>
                        <label className="label">Welcome</label>
                        <div className="value">BackOffice administration</div>
                    </div>
                    <div>
                        <label className="label">Admin</label>
                        <BlockLink label="link-true" href={'/admin/entity/collection/attraction'} name={'Attraction'} link={true} />
                    </div>
                </div>
            </div>
        )
    } else {
        CleanAdminSession();
        return (
            <div className="login">
                <Navigation />
                <div className="admin-form">
                    <form className="form" action={`${HostBackend()}/admin/administrator/login`} method="post">
                        <fieldset>
                            <legend>Admin</legend>
                            <Email value={''} />
                            <Password value={''} />
                            <button className="button">Submit</button>
                        </fieldset>
                    </form>
                </div>
                <Footer />
            </div>
        )
    }
}