import { Text } from '../../Form/Inputs/Text.js';
import { Hidden } from '../../Form/Inputs/Hidden.js';

export const Serie = (props) => {
    const name = (props.el) ? props.el.name : '';
    const id = (props.el) ? props.el.id : '';
    return (
        <>
            <Text name="name" value={name} />
            <Hidden name="id" value={id} />
        </>
    )
}