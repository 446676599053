import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
/* document */
import { Navigation } from '../../Components/Admin/Document/Navigation.js';
import { Footer } from '../../Components/Public/Document/Footer.js';
import { BackTop } from '../../Components/Public/Document/BackTop.js';
import { FormUpdate } from '../../Components/Admin/Form/FormUpdate.js';
import { AutoComplete } from '../../Components/Admin/Functions/AutoComplete.js';

export const Update = () => {
    let { entity } = useParams();
    let { id } = useParams();
    const hosting = HostBackend();
    const [datas, setDatas] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const datasUpdate = await fetch(`${hosting}/admin/form/${entity}?id=${id}`);
            const datas = await datasUpdate.json();
            setDatas(datas);
        };
        fetchData();
    }, [hosting, entity, id]);
    const autocomplete = AutoComplete;
    return (
        <div className="update">
            <Navigation entity={entity} chooser={[]} />
            <div className="admin-form">
                <FormUpdate entity={entity} datas={datas} />
                <a className="back" href={`/admin/entity/collection/${entity}`} alt="back" title="back">
                    <i className="fa fa-reply"></i>
                </a>
            </div>
            <BackTop />
            <Footer />
            <script>{autocomplete()}</script>
        </div>
    )
}