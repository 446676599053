import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../../../Routes/HostBackend.js';
/* document */
import { Select } from '../../Form/Inputs/Select.js';
import { Text } from '../../Form/Inputs/Text.js';
import { Textarea } from '../../Form/Inputs/Textarea.js';
import { Hidden } from '../../Form/Inputs/Hidden.js';

export const DescribePark = (props) => {
    const hosting = HostBackend();
    const [linkChooser, setLinkChooser] = useState([]);
    const name = (props.el) ? props.el.name : '';
    const description = (props.el) ? props.el.description : '';
    const id_link = (props.el) ? props.el.id_link : '';
    const id = (props.el) ? props.el.id : '';
    useEffect(() => {
        const fetchData = async () => {
            const datasLinks = await fetch(`${hosting}/admin/collection/outlinks`);
            const linkChooser = await datasLinks.json();
            setLinkChooser(linkChooser);
        };
        fetchData();
    }, [hosting]);
    return (
        <>
            <Text name="name" value={name} />
            <Textarea name="description" value={description} />
            <Select name="id_link" chooser={linkChooser} value={id_link} />
            <Hidden name="id" value={id} />
        </>
    )
}