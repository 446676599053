export const GetEntitySelector = (entity) => {
    let selector;
    if (entity === 'attraction') 
        selector = 'category';
    else if (entity === 'origin')
        selector = 'origintype';
    else if (entity === 'experiment')
        selector = 'serie';
    else 
        selector = 'sort';
    return selector;
}