import { HostBackend } from '../../../../Routes/HostBackend.js';

export const ButtonDrop = (props) => {
    const hosting = HostBackend();
    const entity = props.entity;
    if (props.check === false) {
        return (
            <div className="button-drop">
                <form className="form" action={`${hosting}/admin/drop/${entity}`} method="POST">
                    <input type="hidden" name="entity" value={entity} className="button" title={entity} />
                    <input type="submit" name="drop" value={`Drop`} className="button" title={entity} />
                </form>
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}