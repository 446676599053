import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
/* document */
import { Navigation } from '../../Components/Admin/Document/Navigation.js';
import { Footer } from '../../Components/Public/Document/Footer.js';
import { BackTop } from '../../Components/Public/Document/BackTop.js';

export const DatabaseGenerate = () => {
    const hash = sessionStorage.getItem('token');
    const hosting = HostBackend();
    const [datas, setDatas] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const datasfetch = await fetch(`${hosting}/admin/collection/experiment`);
            const datas = await datasfetch.json();
            setDatas(datas);
        };
        fetchData();
    }, [hosting]);
    if (hash) {
        return (
            <div className="collection">
                <Navigation />
                <div className="admin-list">
                    <div className="title">
                        <div>{`ADMIN: formate (${datas.length})`}</div>
                        {/* <div>id_serie - id_author - id_description - name - slug - number - route</div> */}
                    </div>
                    {datas.map((item, i) => {
                        let id = item.id;
                        return (
                            <div className="list" key={`b-${i}`}>
                                {`UPDATE experiment SET id_description = '${id+1}' WHERE id = ${id};`}
                            </div>
                        )
                    })}
                </div>
                <BackTop />
                <Footer />
            </div>
        )
    } else {
        return (<></>)
    }
}