import { Slugify } from '../../Admin/Functions/Slugify.js';

export const FilterOriginsType = (origins) => {
    const collection = [];
    const map = new Map();
    for (const el of origins) {
        if (!map.has(el.otname)) {
            map.set(el.otname, true);
            collection.push({
                otid: el.otid,
                otslug: Slugify(el.otname),
                otname: el.otname,
            });
        }
    }
    return collection;
}
