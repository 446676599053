import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
import { FilterExperiments } from '../../Components/Public/Filters/FilterExperiments.js';
/* document */
import { Navigation } from '../../Components/Public/Document/Navigation.js';
import { Header } from '../../Components/Public/Document/Header.js';
import { Footer } from '../../Components/Public/Document/Footer.js';
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { BreadTags } from '../../Components/Public/Document/BreadTags.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockTitle } from '../../Components/Public/Blocks/BlockTitle.js';
import { BlockOutlink } from '../../Components/Public/Blocks/BlockOutlink.js';
import { BlockExperiments } from '../../Components/Public/Blocks/BlockExperiments.js';
import { BackTop } from '../../Components/Public/Document/BackTop.js';
import { ScrollTop } from '../../Components/Public/Functions/ScrollTop.js';

export const Experiments = (props) => {
    const navParks = props.navParks;
    const hosting = HostBackend();
    const [series, setSeries] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [experiments, setExperiments] = useState([]);
    const [body, setBody] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const datasSeries = await fetch(`${hosting}/series`);
            const series = await datasSeries.json();
            setSeries(series);
            const datasAuthors = await fetch(`${hosting}/authors`);
            const authors = await datasAuthors.json();
            setAuthors(authors);
            const datasExperiments = await fetch(`${hosting}/experiments`);
            const experiments = await datasExperiments.json();
            setExperiments(experiments);
            const datasBody = await fetch(`${hosting}/body/experiments`);
            const body = await datasBody.json();
            setBody(body);
        };
        fetchData();
        ScrollTop();
    }, [hosting]);
    const total = experiments.length;
    const items = [];
    body.map((el) => {
        items['description'] = el.description;
        items['created'] = '2002-03-16';
        items['name'] = 'Expériences\nde Jumba Jookiba';
        items['authors'] = authors;
        items['link_name'] = el.link_name;
        items['link_href'] = el.link_href;
        return items;
    })
    return (
        <div className="experiments">
            <Navigation navParks={navParks} />
            <Header />
            <BreadCrumb />
            <section className="infos">
                <div>
                    <BreadTags label="experiments" datas={series} />
                    {series.map((serie, i) => {
                        let label = `${serie.id-1}00`;
                        let title = `Série ${label} - ${serie.name} (${series.length})`;
                        const experiment = FilterExperiments(experiments, serie.id);
                        return (
                            <div key={`i-${i}`}>
                                <div className="title-list" id={`serie-${label}`}>{title}</div>
                                <div className="list" key={`b-${i}`}>
                                    {experiment.map((el, i) => {
                                        let item = {
                                            path: `${label}/${el.number}-${el.slug}`, 
                                            name: el.name, 
                                            number: el.number,
                                            route: el.route, 
                                        };
                                        return (
                                            <BlockLogo label="experiments" item={item} key={`i-${i}`} />
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="description">
                    <BlockTitle label="experiments" item={items} total={total} />
                    <p>{items.description}</p>
                    <BlockOutlink item={items} />
                    <BlockExperiments series={series} experiments={experiments} total={total} />
                </div>
            </section>
            <BackTop />
            <Footer />
        </div>
    )
}
