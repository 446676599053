import { useEffect } from "react";
/* datas */
import { FilterAttractions } from '../../Components/Public/Filters/FilterAttractions.js';
import { FilterOrigins } from "../../Components/Public/Filters/FilterOrigins.js";
import { FilterCategory } from "../../Components/Public/Filters/FilterCategory.js";
import { LogoImgAttraction } from "../../Files/Logos/LogoImgAttraction.js";
/* document */
import { Navigation } from '../../Components/Public/Document/Navigation.js';
import { Header } from '../../Components/Public/Document/Header.js';
import { Footer } from '../../Components/Public/Document/Footer.js';
import { Carrousel } from '../../Components/Public/Carrousel/Carrousel.js';
import { Jumbotron } from '../../Components/Public/Document/Jumbotron.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockCollection } from '../../Components/Public/Blocks/BlockCollection.js';
import { AnimCarrousel } from '../../Components/Public/Functions/AnimCarrousel.js';
import { BackTop} from '../../Components/Public/Document/BackTop.js';
import { ScrollTop } from '../../Components/Public/Functions/ScrollTop.js';

export const Home = (props) => {
    const parks = props.navParks;
    const attractions = props.att;
    const characters = props.cha;
    const origins = props.ori;
    const experiments = props.exp;
    const ttunivers = parks[0] ? parks[0].univers.length + parks[1].univers.length : 0;
    const byOrigins = FilterOrigins('home', props.att);
    const categories = FilterCategory('home', props.att);
    const datas = [
        { label: `${parks.length} parcs` },
        { label: `${ttunivers} univers` },
        { label: `${attractions.length} attractions` },
        { label: `${origins.length} films & séries` },
        { label: `${characters.length} personnages` },
        { label: `${experiments.length} expériences` },
    ]
    const collection = [];
    for (let i = 0; i < attractions.length; i++) {
        collection.push({
            slug: attractions[i].aslug,
            name: attractions[i].aname,
            href: attractions[i].aroute,
            images: LogoImgAttraction('icon', attractions[i].aslug)
        });
    }
    useEffect(() => {
        AnimCarrousel();
        ScrollTop();
    });
    return (
        <div className="home">
            <Navigation navParks={parks} />
            <Header />
            <Carrousel label="home" datas={collection} total={attractions.length} />
            <Jumbotron datas={datas} />
            <section className="infos">
                <div className="block-parks">
                    {parks.map((item, i) => {
                        let attraction = FilterAttractions('park', attractions, item.pid);
                        return (
                            <div className="bloc-infos" key={`i-${i}`}>
                                <BlockLogo label="home" item={item} />
                                <BlockCollection label="home" datas={item.univers} att={attraction} />
                            </div>
                        )
                    })}
                </div>
                <div className="block-collections">
                    <div className="bloc-infos">
                        <BlockCollection label="categories" datas={categories} att={attractions} />
                    </div>
                    <div className="bloc-infos">
                        <BlockCollection label="origins" datas={byOrigins} att={attractions} cha={characters} />
                    </div>
                </div>
            </section>
            <BackTop />
            <Footer />
        </div>
    )
}
