import { ButtonCreate } from '../Form/Buttons/ButtonCreate.js';
import { ButtonDrop } from '../Form/Buttons/ButtonDrop.js';
import { ButtonLink } from '../Form/Buttons/ButtonLink.js';

export const Tables = (props) => {
    const files = props.compare[0];
    const diff = props.compare[1];
    return (
        files.map((item, i) => {
            let find = (diff.filter((e) => e === item.files));
            return (
                <div className="rows" key={`i-${i}`}>
                    <div className="block-rows">
                        <div className="id">{i + 1}</div>
                        <div className="name">{item.files}</div>
                        <div className="item">{item.mysql}</div>
                    </div>
                    <div className="block-buttons">
                        <ButtonLink name={item.files} href={`/admin/entity/collection/${item.files}`} />
                        <ButtonCreate entity={find} check={find.length > 0 ? true : false} />
                        <ButtonDrop entity={item.files} check={find.length > 0 ? true : false} />
                    </div>
                </div>
            )
        })
    )
}
