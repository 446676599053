import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../../../Routes/HostBackend.js';
/* document */
import { Select } from '../../Form/Inputs/Select.js';
import { Text } from '../../Form/Inputs/Text.js';
import { Hidden } from '../../Form/Inputs/Hidden.js';

export const Category = (props) => {
    const hosting = HostBackend();
    const [descriptionChooser, setDescriptionChooser] = useState([]);
    const name = (props.el) ? props.el.name : '';
    const slug = (props.el) ? props.el.slug : '';
    const code = (props.el) ? props.el.code : '';
    const route = (props.el) ? props.el.route : '';
    const id_description = (props.el) ? props.el.id_description : '';
    const id = (props.el) ? props.el.id : '';
    useEffect(() => {
        const fetchData = async () => {
            const datasDescription = await fetch(`${hosting}/admin/collection/describe_category`);
            const descriptionChooser = await datasDescription.json();
            setDescriptionChooser(descriptionChooser);
        };
        fetchData();
    }, [hosting]);
    return (
        <>
            <Text name="name" value={name} />
            <Text name="slug" value={slug} />
            <Text name="code" value={code} />
            <Text name="route" value={route} />
            <Select name="id_description" chooser={descriptionChooser} value={id_description} />
            <Hidden name="id" value={id} />
        </>
    )
}