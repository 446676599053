import { ButtonDelete } from "../Form/Buttons/ButtonDelete.js";
import { ButtonUpdate } from "../Form/Buttons/ButtonUpdate.js";

export const Describes = (props) => {
    const entity = props.entity;
    const datas = props.datas;
    switch (entity) {
        case 'describe_attraction':
        case 'describe_category':
        case 'describe_character':
        case 'describe_experiment':
        case 'describe_origin':
        case 'describe_page':
        case 'describe_park':
        case 'describe_univers':
            return (
                datas.map((el, i) => {
                    return (
                        <div key={`i-${i}`} className="rows">
                            <div className="block-rows">
                                <div className="id">{i + 1}</div>
                                <div className="item">{el.name} <span>({el.id})</span></div>
                            </div>
                            <div className="block-buttons">
                                <ButtonUpdate entity={entity} id={el.id} />
                                <ButtonDelete entity={entity} id={el.id} />
                            </div>
                        </div>
                    )
                }));
        default:
            return (
                <></>
            )
    }

}