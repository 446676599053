/* Entities */
import { Administrator } from './Entities/Administrator.js';
import { Attraction } from './Entities/Attraction.js';
import { Characters } from './Entities/Characters.js';
import { Category } from './Entities/Category.js';
import { Experiment } from './Entities/Experiment.js';
import { Origin } from './Entities/Origin.js';
import { Outlinks } from './Entities/Outlinks.js';
import { Park } from './Entities/Park.js';
import { Univers } from './Entities/Univers.js';
import { User } from './Entities/User.js';
/* Describe */
import { DescribeAttraction } from './Describes/DescribeAttraction.js';
import { DescribeCategory } from './Describes/DescribeCategory.js';
import { DescribeCharacter } from './Describes/DescribeCharacter.js';
import { DescribeExperiment } from './Describes/DescribeExperiment.js';
import { DescribeOrigin } from './Describes/DescribeOrigin.js';
import { DescribePage } from './Describes/DescribePage.js';
import { DescribePark } from './Describes/DescribePark.js';
import { DescribeUnivers } from './Describes/DescribeUnivers.js';
/* Attributes */
import { Author } from './Attributes/Author.js';
import { Evacuation } from './Attributes/Evacuation.js';
import { Handicap } from './Attributes/Handicap.js';
import { Height } from './Attributes/Height.js';
import { Interest } from './Attributes/Interest.js';
import { Origintype } from './Attributes/Origintype.js';
import { Photopass } from './Attributes/Photopass.js';
import { Pmr } from './Attributes/Pmr.js';
import { Premieraccess } from './Attributes/Premieraccess.js';
import { Public } from './Attributes/Public.js';
import { Sensory } from './Attributes/Sensory.js';
import { Singlerider } from './Attributes/Singlerider.js';
import { Serie } from './Attributes/Serie.js';
import { Sort } from './Attributes/Sort.js';

export const Fields = (props) => {
    const item = props.item ? props.item : [];
    switch (props.entity) {
        /* entity */
        case 'administrator':
            return (<Administrator el={item} />);
        case 'attraction':
            return (<Attraction el={item} />);
        case 'characters':
            return (<Characters el={item} />);
        case 'category':
            return (<Category el={item} />);
        case 'origin':
            return (<Origin el={item} />);
        case 'park':
            return (<Park el={item} />)
        case 'univers':
            return (<Univers el={item} />)
        case 'user':
            return (<User el={item} />);
        case 'outlinks':
            return (<Outlinks el={item} />);
        /* describe */
        case 'describe_attraction':
            return (<DescribeAttraction el={item} />);
        case 'describe_category':
            return (<DescribeCategory el={item} />);
        case 'describe_character':
            return (<DescribeCharacter el={item} />);
        case 'describe_experiment':
            return (<DescribeExperiment el={item} />);
        case 'describe_origin':
            return (<DescribeOrigin el={item} />);
        case 'describe_page':
            return (<DescribePage el={item} />);
        case 'describe_park':
            return (<DescribePark el={item} />);
        case 'describe_univers':
            return (<DescribeUnivers el={item} />);
        /* attribute */
        case 'evacuation':
            return (<Evacuation el={item} />);
        case 'experiment':
            return (<Experiment el={item} />)
        case 'author':
            return (<Author el={item} />)
        case 'serie':
            return (<Serie el={item} />)
        case 'handicap':
            return (<Handicap el={item} />);
        case 'height':
            return (<Height el={item} />);
        case 'interest':
            return (<Interest el={item} />);
        case 'origintype':
            return (<Origintype el={item} />)
        case 'photopass':
            return (<Photopass el={item} />);
        case 'pmr':
            return (<Pmr el={item} />);
        case 'premieraccess':
            return (<Premieraccess el={item} />);
        case 'public':
            return (<Public el={item} />);
        case 'sensory':
            return (<Sensory el={item} />);
        case 'singlerider':
            return (<Singlerider el={item} />);
        case 'sort':
            return (<Sort el={item} />);
        default:
            return (<></>)
    }
}