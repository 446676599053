import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../../../Routes/HostBackend.js';
/* document */
import { Select } from '../../Form/Inputs/Select.js';
import { Text } from '../../Form/Inputs/Text.js';
import { Date } from '../../Form/Inputs/Date.js';
import { Hidden } from '../../Form/Inputs/Hidden.js';

export const Origin = (props) => {
    const hosting = HostBackend();
    const [typeChooser, setTypeChooser] = useState([]);
    const [descriptionChooser, setDescriptionChooser] = useState([]);
    const name = (props.el) ? props.el.name : '';
    const slug = (props.el) ? props.el.slug : '';
    const created = (props.el) ? props.el.created : '';
    const author = (props.el) ? props.el.author : '';
    const number = (props.el) ? props.el.number : '';
    const id_type = (props.el) ? props.el.id_type : '';
    const route = (props.el) ? props.el.route : '';
    const id_description = (props.el) ? props.el.id_description : '';
    const id = (props.el) ? props.el.id : '';
    useEffect(() => {
        const fetchData = async () => {
            const datasDescription = await fetch(`${hosting}/admin/collection/describe_origin`);
            const descriptionChooser = await datasDescription.json();
            setDescriptionChooser(descriptionChooser);
            const datasType = await fetch(`${hosting}/admin/collection/origintype`);
            const typeChooser = await datasType.json();
            setTypeChooser(typeChooser);
        };
        fetchData();
    }, [hosting]);
    return (
        <>
            <Text name="name" value={name} />
            <Text name="slug" value={slug} />
            <Date name="created" value={created} />
            <Text name="author" value={author} />
            <Text name="number" value={number} />
            <Select name="id_type" chooser={typeChooser} value={id_type} />
            <Text name="route" value={route} />
            <Select name="id_description" chooser={descriptionChooser} value={id_description} />
            <Hidden name="id" value={id} />
        </>
    )
}