export const GetTable = () => {
    let uri = window.location.href;
    let datas = {};
    if (uri.includes('/admin/')) {
        if (uri.includes('administrator')) datas = { label: 'administrator', table: 'administrator' };
        else if (uri.includes('describe_attraction')) datas = { label: 'describe_attraction', table: 'describe_attraction' };
        else if (uri.includes('describe_category')) datas = { label: 'describe_category', table: 'describe_category' };
        else if (uri.includes('describe_character')) datas = { label: 'describe_character', table: 'describe_character' };
        else if (uri.includes('describe_experiment')) datas = { label: 'describe_experiment', table: 'describe_experiment' };
        else if (uri.includes('describe_origin')) datas = { label: 'describe_origin', table: 'describe_origin' };
        else if (uri.includes('describe_page')) datas = { label: 'describe_page', table: 'describe_page' };
        else if (uri.includes('describe_park')) datas = { label: 'describe_park', table: 'describe_park' };
        else if (uri.includes('describe_univers')) datas = { label: 'describe_univers', table: 'describe_univers' };
        else if (uri.includes('attraction')) datas = { label: 'attraction', table: 'attraction' };
        else if (uri.includes('characters')) datas = { label: 'characters', table: 'characters' };
        else if (uri.includes('category')) datas = { label: 'categories', table: 'category' };
        else if (uri.includes('experiment')) datas = { label: 'experiment', table: 'experiment' };
        else if (uri.includes('origin')) datas = { label: 'origines', table: 'origin' };
        else if (uri.includes('outlinks')) datas = { label: 'outlinks', table: 'outlinks' };
        else if (uri.includes('park')) datas = { label: 'park', table: 'park' };
        else if (uri.includes('univers')) datas = { label: 'univers', table: 'univers' };
        else if (uri.includes('user')) datas = { label: 'user', table: 'user' };
        else datas = { label: 'attraction', table: 'attraction' };
    } else {
        if (uri.includes('personnage')) datas = { label: 'personnage', table: 'characters' };
        else if (uri.includes('films-et-series')) datas = { label: 'origines', table: 'origin' };
        else if (uri.includes('experience')) datas = { label: 'experience', table: 'experiment' };
        else datas = { label: 'attraction', table: 'attraction' };
    }
    return datas;
}