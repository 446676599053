import { CreatedAt } from '../Document/CreatedAt.js';
import { BlockLink } from './BlockLink.js';
import { BlockNameTotalAuthor } from './BlockNameTotalAuthor.js';

export const BlockTitle = (props) => {
    const label = props.label;
    const item = props.item;
    const total = props.total;
    const authors = props.item.authors ? props.item.authors : [];
    switch (props.label) {
        case 'park':
        case 'origins':
        case 'categories':
        case 'characters':
        case 'outlinks':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <BlockNameTotalAuthor total={total} label={label} item={item} />
                    </div>
                </div>
            )
        case 'univers':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <BlockNameTotalAuthor total={total} label={label} item={item} />
                        <BlockLink label="link-true" href={item.proute} name={item.pname} />
                    </div>
                </div>
            )
        case 'category':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <BlockNameTotalAuthor total={total} label={label} item={item} />
                        <BlockLink label="link-true" href={'/categories'} name={'Catégories'} />
                    </div>
                </div>
            )
        case 'origin':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <BlockNameTotalAuthor total={total} label={label} item={item} />
                        <BlockLink label="link-true" href={'/films-et-series'} name={'Origines'} />
                    </div>
                </div>
            )
        case 'character':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <BlockNameTotalAuthor total={total} label={label} item={item} />
                        <BlockLink label="link-true" href={'/personnages'} name={'personnages'} />
                        <BlockLink label="link-true" href={'/origine/walt-disney-imagineering'} name={'Walt Disney Imagineering'} />
                        <BlockLink label={`link-${item.slug}`} href={'/experiences/stitch'} name={'Expérences Stitch'} />
                    </div>
                </div>
            )
        case 'attraction':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <BlockNameTotalAuthor total={total} label={label} item={item} />
                        <BlockLink label="link-true" href={`/categorie/${item.cslug}`} name={item.cname} />
                        <BlockLink label="link-true" href={`/films-et-series/${item.oslug}`} name={item.oname} />
                    </div>
                </div>
            )
        case 'experiments':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <BlockNameTotalAuthor total={total} label={label} item={item} />
                        {authors.map((author, i) => {
                            return (
                                <BlockLink label="link-true" href={author.link} name={author.name} key={`i-${i}`} />
                            )
                        })}
                    </div>
                </div>
            )
        case 'experiment':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <BlockNameTotalAuthor total={total} label={label} item={item} />
                        <BlockLink label="link-true" href={'/experiences'} name={'Expériences'} />
                    </div>
                </div>
            )
        default:
            return (
                <></>
            )
    }
}