import { GetIconCode } from "../Functions/GetIconCode";

export const BlockOutlink = (props) => {
    const link_href = props.item.link_href ? props.item.link_href : '#';
    const link_name = props.item.link_name ? props.item.link_name : 'aucun';
        return (
        <a className="link" href={link_href} target="_blank" rel="noreferrer" >
            <div className="code"><i className={GetIconCode('LK')}></i></div>
            <div className="name">{`Source: ${link_name}`}</div>
        </a>
    )
}