import { useState } from 'react';

export const Selector = (props) => {
    const entity = props.entity;
    const datas = props.chooser;
    const [show, setShow] = useState('hide');
    switch (entity) {
        case 'attraction':
        case 'experiment':
        case 'origin':
            return (
                <div className="selector">
                    <div className="slider" onClick={() => { show === 'show' ? setShow('hide') : setShow('show'); }}>
                        <div className="label">Selector</div>
                        <i className="fa fa-bars"></i>
                    </div>
                    <div className={`parent ${show}`}>
                        {datas.map((item, i) => {
                            const link_href = `/admin/entity/collection/${entity}/${item.id}`
                            return (
                                <a key={`i-${i}`} className="link" href={link_href} name="Sorter">{item.name}</a>
                            )
                        })}
                    </div>
                </div>
            )
        default:
            return (<></>)
    }
}