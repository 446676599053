import { useState } from 'react';
/*document */
import { Selectype } from './Uploads/SelectType.js';
import { SelectTheme } from './Uploads/SelectTheme.js';
import { SelectParent } from './Uploads/SelectParent.js';

export const FormUpload = () => {
    const [files, setFiles] = useState([]);
    const [type, setType] = useState('');
    const [theme, setTheme] = useState('');
    const [themeName, setThemeName] = useState('');
    const [parent, setParent] = useState('');
    const [parentName, setParentName] = useState('');
    const [checkpath, setCheckpath] = useState('Choose type and theme...');
    const changeType = (e) => setType(e);
    const changeTheme = (e) => setTheme(e);
    const changeParent = (e) => setParent(e);
    const loadThemeFromChild = (slug) => {
        setThemeName(slug);
        setCheckpath(`/assets/${type}/${slug}`);
    }
    const loadParentFromChild = (slug) => {
        setParentName(slug); 
        setCheckpath(`/assets/${type}/${themeName}/${slug}`);
    }
    const handleThumbnail = () => {
        const thumbnail = document.querySelector('#thumbnail');
        const tmp = document.querySelector('#tmp');
        for (const file of thumbnail.files) tmp.innerText += `${checkpath}/${file.name}\n`;
        setFiles([...thumbnail.files]);
    }
    const handleSubmit = async (e) => {
        const formData = new FormData();
        e.preventDefault();
        files.forEach((file, i) => { formData.append(`file-${i}`, file); });
        fetch(`/admin/upload/uploading`, { method: 'POST', body: formData }).then(res => res.json());
    }
    return (
        <div className="admin-form">
            <form className="form" onSubmit={handleSubmit}>
                <fieldset>
                    <legend>{`IMAGES: upload ${type}`}</legend>
                    <Selectype value={type} onSelect={changeType} />
                    <SelectTheme value={theme} type={type} onSelect={changeTheme} onDatas={loadThemeFromChild} />
                    <SelectParent value={parent} type={type} theme={theme} onSelect={changeParent} onDatas={loadParentFromChild} />
                    <div className="row">
                        <label className="label" htmlFor="checkpath">checkpath</label>
                        <div id="checkpath">{checkpath}</div>
                    </div>
                    <div className="row">
                        <label className="label" htmlFor="thumbnail">thumbnail</label>
                        <input type="file" id="thumbnail" name="filename" onChange={handleThumbnail} />
                    </div>
                    <pre id="tmp" className="tmp"></pre>
                    <button type="submit">Upload</button>
                </fieldset>
            </form>
        </div>
    )
}