import { Slugify } from '../../Admin/Functions/Slugify.js';

export const FilterOriginsByType = (origins, type) => {
    const collection = [];
    const map = new Map();
    for (const el of origins) {
        if (!map.has(el.oslug) && el.otid === type) {
            map.set(el.oslug, true);
            collection.push({
                otslug: Slugify(el.otname),
                otname: el.otname,
                ocreated: el.ocreated,
                oid: el.oid,
                onumber: el.onumber,
                oname: el.oname,
                oroute: el.oroute,
                oslug: el.oslug,
            });
        }
    }
    return collection;
}
