import { BlockStringTotal } from '../Blocks/BlockStringTotal.js';

export const BlockNameTotalAuthor = (props) => {
    let name = props.item.name;
    if (props.label.match(/univers/)) name = props.item.uname;
    if (props.label.match(/attraction/)) name = props.item.aname;
    return (
        <>
            <div className="title-name">{name}</div>
            <BlockStringTotal label={props.label} total={props.total} />
            <div className="title-author">{props.item.author}</div>
        </>
    )
}