import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HostBackend } from '../HostBackend.js';
import { Categories } from '../../Pages/Public/Categories.js';
import { NotFound } from '../../Pages/Public/NotFound.js';
import { FilterParkAndUnivers } from '../../Components/Public/Filters/FilterParkAndUnivers.js';

export const RouteCategories = () => {
    const hosting = HostBackend();
    const [navAtt, setNavAttractions] = useState([]);
    const [navCha, setNavCharacters] = useState([]);
    const [navOri, setNavOrigins] = useState([]);
    const [navExp, setNavExperiments] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const dataNavAttractions = await fetch(`${hosting}/nav/attractions`);
            const navAtt = await dataNavAttractions.json();
            setNavAttractions(navAtt);
            const dataNavCharacters = await fetch(`${hosting}/nav/characters`);
            const navCha = await dataNavCharacters.json();
            setNavCharacters(navCha);
            const dataNavOrigins = await fetch(`${hosting}/nav/origins`);
            const navOri = await dataNavOrigins.json();
            setNavOrigins(navOri);
            const dataNavExperiments = await fetch(`${hosting}/nav/experiments`);
            const navExp = await dataNavExperiments.json();
            setNavExperiments(navExp);
        };
        fetchData();
    }, [hosting]);
    const navParks = FilterParkAndUnivers(navAtt);
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/categories" element={<Categories navParks={navParks} att={navAtt} car={navCha} ori={navOri} exp={navExp} />} />
                <Route path="*" element={<NotFound navParks={navParks} />} />
            </Routes>
        </BrowserRouter>
    )
}