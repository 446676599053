/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
import { GetDateShort } from '../../Components/Public/Functions/GetDates.js';
import { GetSQLBackup } from '../../Files/Database/GetSQLBackup.js';
/* document */
import { Navigation } from '../../Components/Admin/Document/Navigation.js';
import { Footer } from '../../Components/Public/Document/Footer.js';
import { BackTop } from '../../Components/Public/Document/BackTop.js';
import { Text } from '../../Components/Admin/Form/Inputs/Text.js';

export const DatabaseDump = () => {
    const hash = sessionStorage.getItem('token');
    const backup = GetSQLBackup();
    const hosting = HostBackend();
    const date = new Date();
    const filename = `${GetDateShort(date)}-disneylandparis.sql`;
    if (hash) {
        return (
            <div className="collection">
                <Navigation />
                <div className="admin-form">
                    <form className="form" action={`${hosting}/admin/database/dump`} method="POST">
                        <fieldset>
                            <legend>{`DATABASE: dump`}</legend>
                            <Text name="filename" value={filename} />
                            <input className="button" type="submit" defaultValue="Backup" />
                        </fieldset>
                    </form>
                    <ul className="backup-list">
                        {backup.map((item, i) => {
                            let root = item.split('/')[3];
                            let file = root.split('.')[0];
                            return (<li key={`i-${i}`}>{file}</li>);
                        })}
                    </ul>
                </div>
                <BackTop />
                <Footer />
            </div>
        )
    } else {
        return (<></>)
    }
}