import { useParams } from 'react-router-dom';
/* document */
import { FormUpload } from '../../Components/Admin/Form/FormUpload.js';
import { Navigation } from '../../Components/Admin/Document/Navigation.js';
import { Footer } from '../../Components/Public/Document/Footer.js';
import { BackTop } from '../../Components/Public/Document/BackTop.js';

export const UploadFile = () => {
    const hash = sessionStorage.getItem('token');
    let { entity } = useParams();
    if (hash) {
        return (
            <div className="upload">
                <Navigation entity={entity} chooser={[]} />
                <FormUpload />
                <BackTop />
                <Footer />
            </div>
        )
    } else {
        return (<></>)
    }
}