import { LogoDefault } from './LogoDefault.js';

import { Aladdin } from '../Origins/Aladdin.js';
import { AladdinEtLeRoiDesVoleurs } from '../Origins/AladdinEtLeRoiDesVoleurs.js';
import { AlerteRouge } from '../Origins/AlerteRouge.js';
import { AliceAuPaysDesMerveilles } from '../Origins/AliceAuPaysDesMerveilles.js';
import { AntManEtLaGuepe } from '../Origins/AntManEtLaGuepe.js';
import { AntManEtLaGuepeQuantumania } from '../Origins/AntManEtLaGuepeQuantumania.js';
import { AtlantideLEmpirePerdu } from '../Origins/AtlantideLEmpirePedu.js';
import { AvaloniaLEtrangeVoyage } from '../Origins/AvaloniaLEtrangeVoyage.js';
import { AvengersEndgame } from '../Origins/AvengersEndgame.js';
import { AvengersInfinityWar } from '../Origins/AvengersInfinityWar.js';
import { Bambi } from '../Origins/Bambi.js';
import { Bambi2 } from '../Origins/Bambi2.js';
import { BasilDetectivePrive } from '../Origins/BasilDetectivePrive.js';
import { BernardEtBiancaAuPaysDesKangourous } from '../Origins/BernardEtBiancaAuPaysDesKangourous.js';
import { BienvenueChezLesRobinson } from '../Origins/BienvenueChezLesRobinson.js';
import { BlackPanther } from '../Origins/BlackPanther.js';
import { BlackPantherWakandaForever } from '../Origins/BlackPantherWakandaForever.js';
import { BlackWidow } from '../Origins/BlackWidow.js';
import { BlancheNeigeEtLesSeptNains } from '../Origins/BlancheNeigeEtLesSeptNains.js';
import { Bluey } from '../Origins/Bluey.js';
import { Bonkers } from '../Origins/Bonkers.js';
import { BuzzLEclair } from '../Origins/BuzzLEclair.js';
import { CaptainAmericaBraveNewWorld } from '../Origins/CaptainAmericaBraveNewWorld.js';
import { CaptainMarvel } from '../Origins/CaptainMarvel.js';
import { CarsQuatreRoues } from '../Origins/CarsQuatreRoues.js';
import { Cars2 } from '../Origins/Cars2.js';
import { Cars3 } from '../Origins/Cars3.js';
import { Cendrillon } from '../Origins/Cendrillon.js';
import { Cendrillon2 } from '../Origins/Cendrillon2.js';
import { ChickenLittle } from '../Origins/ChickenLittle.js';
import { ClochetteEtLaCreatureLegendaire } from '../Origins/ClochetteEtLaCreatureLegendaire.js';
import { ClochetteEtLaFeePirate } from '../Origins/ClochetteEtLaFeePirate.js';
import { ClochetteEtLaPierreDeLune } from '../Origins/ClochetteEtLaPierreDeLune.js';
import { ClochetteEtLeSecretDesFees } from '../Origins/ClochetteEtLeSecretDesFees.js';
import { ClochetteEtLExpeditionFeerique } from '../Origins/ClochetteEtLExpeditionFeerique.js';
import { Coco } from '../Origins/Coco.js';
import { CoquinDePrintemps } from '../Origins/CoquinDePrintemps.js';
import { DannyLePetitMoutonNoir } from '../Origins/DannyLePetitMoutonNoir.js';
import { DeadpoolEtWolverine } from '../Origins/DeadpoolEtWolverine.js';
import { DingoEtMax } from '../Origins/DingoEtMax.js';
import { DingoEtMax2 } from '../Origins/DingoEtMax2.js';
import { Dinosaure } from '../Origins/Dinosaure.js';
import { DocMcStuffins } from '../Origins/DocMcStuffins.js';
import { DoctorStrangeInTheMultiverseOfMadness } from '../Origins/DoctorStrangeInTheMultiverseOfMadness.js';
import { Dumbo } from '../Origins/Dumbo.js';
import { Elementaire } from '../Origins/Elementaire.js';
import { ElenaDAvalor} from '../Origins/ElenaDAvalor.js';
import { EnAvant } from '../Origins/EnAvant.js';
import { Encanto } from '../Origins/Encanto.js';
import { FancyNancy } from '../Origins/FancyNancy.js';
import { Fantasia } from '../Origins/Fantasia.js';
import { Fantasia2000 } from '../Origins/Fantasia2000.js';
import { FrereDesOurs } from '../Origins/FrereDesOurs.js';
import { FrereDesOurs2 } from '../Origins/FrereDesOurs2.js';
import { Hercule } from '../Origins/Hercule.js';
import { IlEtaitUneFois } from '../Origins/IlEtaitUneFois.js';
import { IlEtaitUneFois2 } from '../Origins/IlEtaitUneFois2.js';
import { IndianaJonesEtLeTempleMaudit } from '../Origins/IndianaJonesEtLeTempleMaudit.js';
import { JakeEtLesPiratesDuPaysImaginaire } from '../Origins/JakeEtLesPiratesDuPaysImaginaire.js';
import { JamesEtLaPecheGeante } from '../Origins/JamesEtLaPecheGeante.js';
import { JeanChristopheEtWinnie } from '../Origins/JeanChristopheEtWinnie.js';
import { KimPossible } from '../Origins/KimPossible.js';
import { KuzcoLEmpereurMegalo } from '../Origins/KuzcoLEmpereurMegalo.js';
import { Kuzco2 } from '../Origins/Kuzco2.js';
import { LaBandeAPicsouLeFilm } from '../Origins/LaBandeAPicsouLeFilm.js';
import { LaBelleAuBoisDormant } from '../Origins/LaBelleAuBoisDormant.js';
import { LaBelleEtLaBete } from '../Origins/LaBelleEtLaBete.js';
import { LaBelleEtLaBete2 } from '../Origins/LaBelleEtLaBete2.js';
import { LaBelleEtLeClochard } from '../Origins/LaBelleEtLeClochard.js';
import { LaBelleEtLeClochard2 } from '../Origins/LaBelleEtLeClochard2.js';
import { LaBoiteAMusique } from '../Origins/LaBoiteAMusique.js';
import { LaFeeClochette } from '../Origins/LaFeeClochette.js';
import { LaFermeSeRebelle } from '../Origins/LaFermeSeRebelle.js';
import { LAgeDeGlaceLesAventuresDeBuckWild } from '../Origins/LAgeDeGlaceLesAventuresDeBuckWild.js';
import { LaHaut } from '../Origins/LaHaut.js';
import { LaPetiteSirene } from '../Origins/LaPetiteSirene.js';
import { LaPetiteSirene2 } from '../Origins/LaPetiteSirene2.js';
import { LaPlaneteAuTresor } from '../Origins/LaPlaneteAuTresor.js';
import { LaPrincesseEtLaGrenouille } from '../Origins/LaPrincesseEtLaGrenouille.js';
import { LaReineDesNeiges } from '../Origins/LaReineDesNeiges.js';
import { LaReineDesNeiges2 } from '../Origins/LaReineDesNeiges2.js';
import { LeBossuDeNotreDame } from '../Origins/LeBossuDeNotreDame.js';
import { LeBossuDeNotreDame2 } from '../Origins/LeBossuDeNotreDame2.js';
import { LeCrapaudEtLeMaitreDEcole } from '../Origins/LeCrapaudEtLeMaitreDEcole.js';
import { LeDragonRecalcitrant } from '../Origins/LeDragonRecalcitrant.js';
import { LeLivreDeLaJungle } from '../Origins/LeLivreDeLaJungle.js';
import { LeLivreDeLaJungle2 } from '../Origins/LeLivreDeLaJungle2.js';
import { LeMondeDeDory } from '../Origins/LeMondeDeDory.js';
import { LeMondeDeNemo } from '../Origins/LeMondeDeNemo.js';
import { LeRetourDeJafar } from '../Origins/LeRetourDeJafar.js';
import { LeRetourDeMaryPoppins } from '../Origins/LeRetourDeMaryPoppins.js';
import { LeRoiLion } from '../Origins/LeRoiLion.js';
import { LeRoiLion2 } from '../Origins/LeRoiLion2.js';
import { LeRoiLion3 } from '../Origins/LeRoiLion3.js';
import { LeSecretDeLaPetiteSirene } from '../Origins/LeSecretDeLaPetiteSirene.js';
import { LesEternels } from '../Origins/LesEternels.js';
import { LesGardiensDeLaGalaxieVolume3 } from '../Origins/LesGardiensDeLaGalaxieVolume3.js';
import { LeSortilegeDeCendrillon } from '../Origins/LeSortilegeDeCendrillon.js';
import { LeVoyageDArlo } from '../Origins/LeVoyageDArlo.js';
import { Les101Dalmatiens } from '../Origins/Les101Dalmatiens.js';
import { Les101Dalmatiens2 } from '../Origins/Les101Dalmatiens2.js';
import { LesAristochats } from '../Origins/LesAristochats.js';
import { LesAventuresDeBernardEtBianca } from '../Origins/LesAventuresDeBernardEtBianca.js';
import { LesAventuresDePetitGourou } from '../Origins/LesAventuresDePetitGourou.js';
import { LesAventuresDePorcinet } from '../Origins/LesAventuresDePorcinet.js';
import { LesAventuresDeTigrou } from '../Origins/LesAventuresDeTigrou.js';
import { LesAventuresDeWinnieLOurson } from '../Origins/LesAventuresDeWinnieLOurson.js';
import { LesCountryBears } from '../Origins/LesCountryBears.js';
import { LesIndestructibles } from '../Origins/LesIndestructibles.js';
import { LesIndestructibles2 } from '../Origins/LesIndestructibles2.js';
import { LesMondesDeRalph } from '../Origins/LesMondesDeRalph.js';
import { LesNouveauxHeros } from '../Origins/LesNouveauxHeros.js';
import { LesPetitsEinstein } from '../Origins/LesPetitsEinstein.js';
import { LesTroisCaballeros } from '../Origins/LesTroisCaballero.js';
import { LEtrangeNoelDeMonsieurJack } from '../Origins/LEtrangeNoelDeMonsieurJack.js';
import { LiloEtStitch } from '../Origins/LiloEtStitch.js';
import { LiloEtStitch2 } from '../Origins/LiloEtStitch2.js';
import { Luca } from '../Origins/Luca.js';
import { Marvel } from '../Origins/Marvel.js';
import { MaryPoppins } from '../Origins/MaryPoppins.js';
import { MelodieCocktail } from '../Origins/MelodieCocktail.js';
import { MelodieDuSud } from '../Origins/MelodieDuSud.js';
import { MerlinLEnchanteur } from '../Origins/MerlinLEnchanteur.js';
import { MickeyMouse } from '../Origins/MicketMouse.js';
import { MickeyDonaldDingoLesTroisMousquetaires } from '../Origins/MickeyDonaldDingoLesTroisMousquetaires.js';
import { Miraculous } from '../Origins/Miraculous.js';
import { MiraDetectiveRoyale } from '../Origins/MiraDetectiveRoyale.js';
import { MilleUnePattes } from '../Origins/MilleUnePattes.js';
import { MonstresAcademy } from '../Origins/MonstresAcademy.js';
import { MonstresEtCie } from '../Origins/MontresEtCie.js';
import { MufasaLeRoiLion } from '../Origins/MufasaLeRoiLion.js';
import { Mulan } from '../Origins/Mulan.js';
import { Mulan2 } from '../Origins/Mulan2.js';
import { OliverEtCompagnie } from '../Origins/OliverEtCompagnie.js';
import { PeterEtElliottLeDragon } from '../Origins/PeterEtElliottLeDragon.js';
import { PeterPan } from '../Origins/PeterPan.js';
import { PeterPan2 } from '../Origins/PeterPan2.js';
import { PhineasEtFerb } from '../Origins/PhineasEtFerb.js';
import { Pinocchio } from '../Origins/Pinocchio.js';
import { PiratesOfTheCaribbean } from '../Origins/PiratesOfTheCaribbean.js';
import { Planes } from '../Origins/Planes.js';
import { Planes2 } from '../Origins/Planes2.js';
import { Pocahontas } from '../Origins/Pocahontas.js';
import { Pocahontas2 } from '../Origins/Pocahontas2.js';
import { PrincesseSofia } from '../Origins/PrincesseSofia.js';
import { QuiVeutLaPeauDeRogerRabbit } from '../Origins/QuiVeutLaPeauDeRogerRabbit.js';
import { Raiponce } from '../Origins/Raiponce.js';
import { Ralph20 } from '../Origins/Ralph20.js';
import { Ratatouille } from '../Origins/Ratatouille.js';
import { RayaEtLeDernierDragon } from '../Origins/RayaEtLeDernierDragon.js';
import { Rebelle } from '../Origins/Rebelle.js';
import { RobinDesBois } from '../Origins/RobinDesBois.js';
import { RoxEtRouky } from '../Origins/RoxEtRouky.js';
import { RoxEtRouky2 } from '../Origins/RoxEtRouky2.js';
import { SaludosAmigos } from '../Origins/SaludosAmigos.js';
import { ShangChiEtLaLegendeDesDixAnneaux } from '../Origins/ShangChiEtLaLegendeDesDixAnneaux.js';
import { Soul } from '../Origins/Soul.js';
import { StitchLeFilm } from '../Origins/StitchLeFilm.js';
import { StarWars } from '../Origins/StarWars.js';
import { Starwars1LaMenaceFantome } from '../Origins/Starwars1LaMenaceFantome.js';
import { Starwars2LAttaqueDesClones } from '../Origins/Starwars2LAttaqueDesClones.js';
import { Starwars3LaRevancheDesSith } from '../Origins/Starwars3LaRevancheDesSith.js';
import { Starwars4UnNouvelEspoir } from '../Origins/Starwars4UnNouvelEspoir.js';
import { Starwars5LEmpireContreAttaque } from '../Origins/Starwars5LEmpireContreAttaque.js';
import { Starwars6LeRetourDuJedi } from '../Origins/Starwars6LeRetourDuJedi.js';
import { Starwars7LeReveilDeLaForce } from '../Origins/Starwars7LeReveilDeLaForce.js';
import { Starwars8LesDerniersJedi } from '../Origins/Starwars8LesDerniersJedi.js';
import { Starwars9LAscensionDeSkywalker } from '../Origins/Starwars9LAscensionDeSkywalker.js';
import { TaramEtLeChaudronMagique } from '../Origins/TaramEtLeChaudronMagique.js';
import { Tarzan } from '../Origins/Tarzan.js';
import { Tarzan2 } from '../Origins/Tarzan2.js';
import { TheMarvels } from '../Origins/TheMarvels.js';
import { TheWild } from '../Origins/TheWild.js';
import { ThorLoveAndThunder } from '../Origins/ThorLoveAndThunder.js';
import { Thunderbolts } from '../Origins/Thunderbolts.js';
import { TicEtTacLesRangersDuRisque } from '../Origins/TicEtTacLesRangersDuRisque.js';
import { ToyStory } from '../Origins/ToyStory.js';
import { ToyStory2 } from '../Origins/ToyStory2.js';
import { ToyStory3 } from '../Origins/ToyStory3.js';
import { ToyStory4 } from '../Origins/ToyStory4.js';
import { Vaiana } from '../Origins/Vaiana.js';
import { Vaiana2 } from '../Origins/Vaiana2.js';
import { ViceVersa } from '../Origins/ViceVersa.js';
import { ViceVersa2 } from '../Origins/ViceVersa2.js';
import { Vampirina } from '../Origins/Vampirina.js';
import { VictoireDansLesAirs } from '../Origins/VictoireDansLesAirs.js';
import { VoltStarMalgreLui } from '../Origins/VoltStarMalgreLui.js';
import { WallE } from '../Origins/WallE.js';
import { WaltDisneyImagineering } from '../Origins/WaltDisneyImagineering.js';
import { WinnieLOurson } from '../Origins/WinnieLOurson.js';
import { WinnieLOurson2 } from '../Origins/WinnieLOurson2.js';
import { WinnieLOursonEtLEfelant } from '../Origins/WinnieLOursonEtLEfelant.js';
import { WishAshaEtLaBonneEtoile } from '../Origins/WishAshaEtLaBonneEtoile.js';
import { Zootopie } from '../Origins/Zootopie.js';
import { LApprentieSorciere } from '../Origins/LApprentieSorciere.js';
import { LogoImgDefault } from './LogoImgDefault.js';

export const LogoImgOrigin = (type, slug) => {
    let datas = [];
    if (slug === '1001-pattes') datas = MilleUnePattes();
    if (slug === 'aladdin') datas = Aladdin();
    if (slug === 'aladdin-et-le-roi-des-voleurs') datas = AladdinEtLeRoiDesVoleurs();
    if (slug === 'alerte-rouge') datas = AlerteRouge();
    if (slug === 'alice-au-pays-des-merveilles') datas = AliceAuPaysDesMerveilles();
    if (slug === 'ant-man-et-la-guepe') datas = AntManEtLaGuepe();
    if (slug === 'ant-man-et-la-guepe-quantumania') datas = AntManEtLaGuepeQuantumania();
    if (slug === 'atlantide-l-empire-perdu') datas = AtlantideLEmpirePerdu();
    if (slug === 'avalonia-l-etrange-voyage') datas = AvaloniaLEtrangeVoyage();
    if (slug === 'avengers-endgame') datas = AvengersEndgame();
    if (slug === 'avengers-infinity-war') datas = AvengersInfinityWar();
    if (slug === 'bambi') datas = Bambi();
    if (slug === 'bambi-2') datas = Bambi2();
    if (slug === 'basil-detective-prive') datas = BasilDetectivePrive();
    if (slug === 'bernard-et-bianca-au-pays-des-kangourous') datas = BernardEtBiancaAuPaysDesKangourous();
    if (slug === 'bienvenue-chez-les-robinson') datas = BienvenueChezLesRobinson();
    if (slug === 'black-panther') datas = BlackPanther();
    if (slug === 'black-panther-wakanda-forever') datas = BlackPantherWakandaForever();
    if (slug === 'black-widow') datas = BlackWidow();
    if (slug === 'blanche-neige-et-les-sept-nains') datas = BlancheNeigeEtLesSeptNains();
    if (slug === 'bluey') datas = Bluey();
    if (slug === 'bonkers') datas = Bonkers();
    if (slug === 'buzz-l-eclair') datas = BuzzLEclair();
    if (slug === 'captain-america-brave-new-world') datas = CaptainAmericaBraveNewWorld();
    if (slug === 'captain-marvel') datas = CaptainMarvel();
    if (slug === 'cars-quatre-roues') datas = CarsQuatreRoues();
    if (slug === 'cars-2') datas = Cars2();
    if (slug === 'cars-3') datas = Cars3();
    if (slug === 'cendrillon') datas = Cendrillon();
    if (slug === 'cendrillon-2') datas = Cendrillon2();
    if (slug === 'chicken-little') datas = ChickenLittle();
    if (slug === 'clochette-et-la-creature-legendaire') datas = ClochetteEtLaCreatureLegendaire();
    if (slug === 'clochette-et-la-fee-pirate') datas = ClochetteEtLaFeePirate();
    if (slug === 'clochette-et-la-pierre-de-lune') datas = ClochetteEtLaPierreDeLune();
    if (slug === 'clochette-et-le-secret-des-fees') datas = ClochetteEtLeSecretDesFees();
    if (slug === 'clochette-et-l-expedition-feerique') datas = ClochetteEtLExpeditionFeerique();
    if (slug === 'coco') datas = Coco();
    if (slug === 'coquin-de-printemps') datas = CoquinDePrintemps();
    if (slug === 'danny-le-petit-mouton-noir') datas = DannyLePetitMoutonNoir();
    if (slug === 'deadpool-et-wolverine') datas = DeadpoolEtWolverine();
    if (slug === 'dingo-et-max') datas = DingoEtMax();
    if (slug === 'dingo-et-max-2') datas = DingoEtMax2();
    if (slug === 'dinosaure') datas = Dinosaure();
    if (slug === 'doc-mc-stuffins') datas = DocMcStuffins();
    if (slug === 'doctor-strange-in-the-multiverse-of-madness') datas = DoctorStrangeInTheMultiverseOfMadness();
    if (slug === 'dumbo') datas = Dumbo();
    if (slug === 'elementaire') datas = Elementaire();
    if (slug === 'elena-d-avalor') datas = ElenaDAvalor();
    if (slug === 'en-avant') datas = EnAvant();
    if (slug === 'encanto') datas = Encanto();
    if (slug === 'fancy-nancy') datas = FancyNancy();
    if (slug === 'fantasia') datas = Fantasia();
    if (slug === 'fantasia-2000') datas = Fantasia2000();
    if (slug === 'frere-des-ours') datas = FrereDesOurs();
    if (slug === 'frere-des-ours-2') datas = FrereDesOurs2();
    if (slug === 'hercule') datas = Hercule();
    if (slug === 'il-etait-une-fois') datas = IlEtaitUneFois();
    if (slug === 'il-etait-une-fois-2') datas = IlEtaitUneFois2();
    if (slug === 'indiana-jones-et-le-temple-maudit') datas = IndianaJonesEtLeTempleMaudit();
    if (slug === 'jake-et-les-pirates-du-pays-imaginaire') datas = JakeEtLesPiratesDuPaysImaginaire();
    if (slug === 'james-et-la-peche-geante') datas = JamesEtLaPecheGeante();
    if (slug === 'jean-christophe-et-winnie') datas = JeanChristopheEtWinnie();
    if (slug === 'kim-possible') datas = KimPossible();
    if (slug === 'kuzco-l-empereur-megalo') datas = KuzcoLEmpereurMegalo();
    if (slug === 'kuzco-2') datas = Kuzco2();
    if (slug === 'la-bande-a-picsou-le-film') datas = LaBandeAPicsouLeFilm();
    if (slug === 'la-belle-au-bois-dormant') datas = LaBelleAuBoisDormant();
    if (slug === 'la-belle-et-la-bete') datas = LaBelleEtLaBete();
    if (slug === 'la-belle-et-la-bete-2') datas = LaBelleEtLaBete2();
    if (slug === 'la-belle-et-le-clochard') datas = LaBelleEtLeClochard();
    if (slug === 'la-belle-et-le-clochard-2') datas = LaBelleEtLeClochard2();
    if (slug === 'la-boite-a-musique') datas = LaBoiteAMusique();
    if (slug === 'la-fee-clochette') datas = LaFeeClochette();
    if (slug === 'la-ferme-se-rebelle') datas = LaFermeSeRebelle();
    if (slug === 'l-age-de-glace-les-aventures-de-buck-wild') datas = LAgeDeGlaceLesAventuresDeBuckWild();
    if (slug === 'la-haut') datas = LaHaut();
    if (slug === 'la-petite-sirene') datas = LaPetiteSirene();
    if (slug === 'la-petite-sirene-2') datas = LaPetiteSirene2();
    if (slug === 'la-planete-au-tresor') datas = LaPlaneteAuTresor();
    if (slug === 'l-apprentie-sorciere') datas = LApprentieSorciere();
    if (slug === 'la-princesse-et-la-grenouille') datas = LaPrincesseEtLaGrenouille();
    if (slug === 'la-reine-des-neiges') datas = LaReineDesNeiges();
    if (slug === 'la-reine-des-neiges-2') datas = LaReineDesNeiges2();
    if (slug === 'le-bossu-de-notre-dame') datas = LeBossuDeNotreDame();
    if (slug === 'le-bossu-de-notre-dame-2') datas = LeBossuDeNotreDame2();
    if (slug === 'le-crapaud-et-le-maitre-d-ecole') datas = LeCrapaudEtLeMaitreDEcole();
    if (slug === 'le-dragon-recalcitrant') datas = LeDragonRecalcitrant();
    if (slug === 'le-livre-de-la-jungle') datas = LeLivreDeLaJungle();
    if (slug === 'le-livre-de-la-jungle-2') datas = LeLivreDeLaJungle2();
    if (slug === 'le-monde-de-dory') datas = LeMondeDeDory();
    if (slug === 'le-monde-de-nemo') datas = LeMondeDeNemo();
    if (slug === 'le-retour-de-jafar') datas = LeRetourDeJafar();
    if (slug === 'le-retour-de-mary-poppins') datas = LeRetourDeMaryPoppins();
    if (slug === 'le-roi-lion') datas = LeRoiLion();
    if (slug === 'le-roi-lion-2') datas = LeRoiLion2();
    if (slug === 'le-roi-lion-3') datas = LeRoiLion3();
    if (slug === 'le-secret-de-la-petite-sirene') datas = LeSecretDeLaPetiteSirene();
    if (slug === 'les-eternels') datas = LesEternels();
    if (slug === 'les-gardiens-de-la-galaxie-volume-3') datas = LesGardiensDeLaGalaxieVolume3();
    if (slug === 'le-sortilege-de-cendrillon') datas = LeSortilegeDeCendrillon();
    if (slug === 'le-voyage-d-arlo') datas = LeVoyageDArlo();
    if (slug === 'les-101-dalmatiens') datas = Les101Dalmatiens();
    if (slug === 'les-101-dalmatiens-2') datas = Les101Dalmatiens2();
    if (slug === 'les-aristochats') datas = LesAristochats();
    if (slug === 'les-aventures-de-bernard-et-bianca') datas = LesAventuresDeBernardEtBianca();
    if (slug === 'les-aventures-de-petit-gourou') datas = LesAventuresDePetitGourou();
    if (slug === 'les-aventures-de-porcinet') datas = LesAventuresDePorcinet();
    if (slug === 'les-aventures-de-tigrou') datas = LesAventuresDeTigrou();
    if (slug === 'les-aventures-de-winnie-l-ourson') datas = LesAventuresDeWinnieLOurson();
    if (slug === 'les-country-bears') datas = LesCountryBears();
    if (slug === 'les-indestructibles') datas = LesIndestructibles();
    if (slug === 'les-indestructibles-2') datas = LesIndestructibles2();
    if (slug === 'les-mondes-de-ralph') datas = LesMondesDeRalph();
    if (slug === 'les-nouveaux-heros') datas = LesNouveauxHeros();
    if (slug === 'les-petits-einstein') datas = LesPetitsEinstein();
    if (slug === 'les-trois-caballeros') datas = LesTroisCaballeros();
    if (slug === 'l-etrange-noel-de-monsieur-jack') datas = LEtrangeNoelDeMonsieurJack();
    if (slug === 'lilo-et-stitch') datas = LiloEtStitch();
    if (slug === 'lilo-et-stitch-2') datas = LiloEtStitch2();
    if (slug === 'luca') datas = Luca();
    if (slug === 'marvel') datas = Marvel();
    if (slug === 'mary-poppins') datas = MaryPoppins();
    if (slug === 'melodie-cocktail') datas = MelodieCocktail();
    if (slug === 'melodie-du-sud') datas = MelodieDuSud();
    if (slug === 'merlin-l-enchanteur') datas = MerlinLEnchanteur();
    if (slug === 'mickey-mouse') datas = MickeyMouse();
    if (slug === 'mickey-donald-dingo-les-trois-mousquetaires') datas = MickeyDonaldDingoLesTroisMousquetaires();
    if (slug === 'miraculous') datas = Miraculous();
    if (slug === 'mira-detective-royale') datas = MiraDetectiveRoyale();
    if (slug === 'monstres-academy') datas = MonstresAcademy();
    if (slug === 'monstres-et-cie') datas = MonstresEtCie();
    if (slug === 'mufasa-le-roi-lion') datas = MufasaLeRoiLion();
    if (slug === 'mulan') datas = Mulan();
    if (slug === 'mulan-2') datas = Mulan2();
    if (slug === 'oliver-et-compagnie') datas = OliverEtCompagnie();
    if (slug === 'peter-et-elliott-le-dragon') datas = PeterEtElliottLeDragon();
    if (slug === 'peter-pan') datas = PeterPan();
    if (slug === 'peter-pan-2') datas = PeterPan2();
    if (slug === 'phineas-et-ferb') datas = PhineasEtFerb();
    if (slug === 'pinocchio') datas = Pinocchio();
    if (slug === 'pirates-of-the-caribbean') datas = PiratesOfTheCaribbean();
    if (slug === 'planes') datas = Planes();
    if (slug === 'planes-2') datas = Planes2();
    if (slug === 'pocahontas') datas = Pocahontas();
    if (slug === 'pocahontas-2') datas = Pocahontas2();
    if (slug === 'princesse-sofia') datas = PrincesseSofia();
    if (slug === 'qui-veut-la-peau-de-roger-rabbit') datas = QuiVeutLaPeauDeRogerRabbit();
    if (slug === 'raiponce') datas = Raiponce();
    if (slug === 'ralph-2-0') datas = Ralph20();
    if (slug === 'ratatouille') datas = Ratatouille();
    if (slug === 'raya-et-le-dernier-dragon') datas = RayaEtLeDernierDragon();
    if (slug === 'rebelle') datas = Rebelle();
    if (slug === 'robin-des-bois') datas = RobinDesBois();
    if (slug === 'rox-et-rouky') datas = RoxEtRouky();
    if (slug === 'rox-et-rouky-2') datas = RoxEtRouky2();
    if (slug === 'saludos-amigos') datas = SaludosAmigos();
    if (slug === 'shang-chi-et-la-legende-des-dix-anneaux') datas = ShangChiEtLaLegendeDesDixAnneaux();
    if (slug === 'soul') datas = Soul();
    if (slug === 'stitch-le-film') datas = StitchLeFilm();
    if (slug === 'star-wars') datas = StarWars();
    if (slug === 'star-wars-1-la-menace-fantome') datas = Starwars1LaMenaceFantome();
    if (slug === 'star-wars-2-l-attaque-des-clones') datas = Starwars2LAttaqueDesClones();
    if (slug === 'star-wars-3-la-revanche-des-sith') datas = Starwars3LaRevancheDesSith();
    if (slug === 'star-wars-4-un-nouvel-espoir') datas = Starwars4UnNouvelEspoir();
    if (slug === 'star-wars-5-l-empire-contre-attaque') datas = Starwars5LEmpireContreAttaque();
    if (slug === 'star-wars-6-le-retour-du-jedi') datas = Starwars6LeRetourDuJedi();
    if (slug === 'star-wars-7-le-reveil-de-la-force') datas = Starwars7LeReveilDeLaForce();
    if (slug === 'star-wars-8-les-derniers-jedi') datas = Starwars8LesDerniersJedi();
    if (slug === 'star-wars-9-l-ascension-de-skywalker') datas = Starwars9LAscensionDeSkywalker();
    if (slug === 'taram-et-le-chaudron-magique') datas = TaramEtLeChaudronMagique();
    if (slug === 'tarzan') datas = Tarzan();
    if (slug === 'tarzan-2') datas = Tarzan2();
    if (slug === 'the-marvels') datas = TheMarvels();
    if (slug === 'the-wild') datas = TheWild();
    if (slug === 'thor-love-and-thunder') datas = ThorLoveAndThunder();
    if (slug === 'thunderbolts') datas = Thunderbolts();
    if (slug === 'tic-et-tac-les-rangers-du-risque') datas = TicEtTacLesRangersDuRisque();
    if (slug === 'toy-story') datas = ToyStory();
    if (slug === 'toy-story-2') datas = ToyStory2();
    if (slug === 'toy-story-3') datas = ToyStory3();
    if (slug === 'toy-story-4') datas = ToyStory4();
    if (slug === 'vaiana') datas = Vaiana();
    if (slug === 'vaiana-2') datas = Vaiana2();
    if (slug === 'vice-versa') datas = ViceVersa();
    if (slug === 'vice-versa-2') datas = ViceVersa2();
    if (slug === 'vampirina') datas = Vampirina();
    if (slug === 'victoire-dans-les-airs') datas = VictoireDansLesAirs();
    if (slug === 'volt-star-malgre-lui') datas = VoltStarMalgreLui();
    if (slug === 'wall-e') datas = WallE();
    if (slug === 'walt-disney-imagineering') datas = WaltDisneyImagineering();
    if (slug === 'winnie-l-ourson') datas = WinnieLOurson();
    if (slug === 'winnie-l-ourson-2') datas = WinnieLOurson2();
    if (slug === 'winnie-l-ourson-et-l-efelant') datas = WinnieLOursonEtLEfelant();
    if (slug === 'wish-asha-et-la-bonne-etoile') datas = WishAshaEtLaBonneEtoile();
    if (slug === 'zootopie') datas = Zootopie();
    /* origins */
    if (type === 'icon') return datas.length > 0 ? datas[0] : LogoDefault();
    /* origin */
    if (type === 'img') return datas.length > 0 ? datas : LogoImgDefault('img');
}