import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
/* datas */
import { HostBackend } from '../HostBackend.js';
/* document */
import { Home } from '../../Pages/Public/Home.js';
import { NotFound } from '../../Pages/Public/NotFound.js';

export const RouteHome = (props) => {
    const navPar = props.navParks;
    const hosting = HostBackend();
    const [navAtt, setNavAttractions] = useState([]);
    const [navCha, setNavCharacters] = useState([]);
    const [navOri, setNavOrigins] = useState([]);
    const [navExp, setNavExperiments] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const dataNavAttractions = await fetch(`${hosting}/nav/attractions`);
            const navAtt = await dataNavAttractions.json();
            setNavAttractions(navAtt);
            const dataNavCharacters = await fetch(`${hosting}/nav/characters`);
            const navCha = await dataNavCharacters.json();
            setNavCharacters(navCha);
            const dataNavOrigins = await fetch(`${hosting}/nav/origins`);
            const navOri = await dataNavOrigins.json();
            setNavOrigins(navOri);
            const dataNavExperiments = await fetch(`${hosting}/nav/experiments`);
            const navExp = await dataNavExperiments.json();
            setNavExperiments(navExp);
        };
        fetchData();
    }, [hosting]);
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home navParks={navPar} att={navAtt} cha={navCha} ori={navOri} exp={navExp} />} />
                <Route path="*" element={<NotFound navParks={navPar} />} />
            </Routes>
        </BrowserRouter>
    )
}