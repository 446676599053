import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
import { FilterParks } from '../../Components/Public/Filters/FilterParks.js';
import { FilterAttractions } from '../../Components/Public/Filters/FilterAttractions.js';
import { FilterCategory } from '../../Components/Public/Filters/FilterCategory.js';
import { LogoUnivers } from '../../Files/Logos/LogoUnivers.js';
/* document */
import { Navigation } from '../../Components/Public/Document/Navigation.js';
import { Header } from '../../Components/Public/Document/Header.js';
import { Footer } from '../../Components/Public/Document/Footer.js';
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { Carrousel } from '../../Components/Public/Carrousel/Carrousel.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockCollection } from '../../Components/Public/Blocks/BlockCollection.js';
import { BlockTitle } from '../../Components/Public/Blocks/BlockTitle.js';
import { BlockOutlink } from '../../Components/Public/Blocks/BlockOutlink.js';
import { BackTop} from '../../Components/Public/Document/BackTop.js';
import { ScrollTop } from '../../Components/Public/Functions/ScrollTop.js';

export const Park = (props) => {
    const navParks = props.par;
    const hosting = HostBackend();
    const id = parseInt(props.id);
    const [body, setBody] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const bodyDatas = await fetch(`${hosting}/body/park?id=${id}`);
            const body = await bodyDatas.json();
            setBody(body);
        };
        fetchData();
        ScrollTop();
    }, [hosting, id]);
    const park = FilterParks(props.att, id);
    const univers = park[0].univers;
    const attractions = FilterAttractions('park', props.att, id);
    const categories = FilterCategory('park', props.att, id);
    const total = attractions.length;
    const collection = [];
    let total_img = 0;
    for (let i = 0; i < univers.length; i++) {
        collection.push({
            slug: univers[i].uslug,
            name: univers[i].uname,
            href: univers[i].uroute,
            images: LogoUnivers(univers[i].uslug)
        });
        total_img = univers.length;
    }
    return (
        <div className="park">
            <Navigation navParks={navParks} />
            <Header />
            <Carrousel label="park" datas={collection} total={total_img} />
            <BreadCrumb />
            <section className="infos">
                {park.map((item, i) => {
                    return (
                        <div className="bloc-infos" key={`i-${i}`}>
                            <BlockLogo label="park" item={item} />
                            <BlockCollection label="park" datas={univers} att={attractions} />
                            <BlockCollection label="categories" datas={categories} att={attractions}/>
                            <BlockCollection label="attractions" datas={attractions} />
                        </div>
                    )
                })}
                {body.map((item, i) => {
                    return (
                        <div className="description" key={`i-${i}`}>
                            <BlockTitle label="park" item={item} total={total} />
                            <p>{item.description}</p>
                            <BlockOutlink item={item} />
                        </div>
                    )
                })}
            </section>
            <BackTop />
            <Footer />
        </div>
    )
}