import { BrowserRouter, Routes, Route } from 'react-router-dom';
/* admin routes */
import PrivateRoute from '../../Components/Admin/Functions/PrivateRoute.js';
import { DatabaseDump } from '../../Pages/Admin/DatabaseDump.js';
import { DatabaseCollection } from '../../Pages/Admin/DatabaseCollection.js';
import { DatabaseGenerate } from '../../Pages/Admin/DatabaseGenerate.js';
import { NotFound } from '../../Pages/Public/NotFound.js';

export const RouteDatabase = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<PrivateRoute />}>
                    <Route path={`/admin/database/collection`} element={<DatabaseCollection />} />
                    <Route path={`/admin/database/generate`} element={<DatabaseGenerate />} />
                    <Route path={`/admin/database/backup`} element={<DatabaseDump />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}