import { BrowserRouter, Routes, Route } from 'react-router-dom';
/* admin routes */
import PrivateRoute from '../../Components/Admin/Functions/PrivateRoute.js';
import { UploadFile } from '../../Pages/Admin/UploadFile.js';
import { NotFound } from '../../Pages/Public/NotFound.js';

export const RouteUpload = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<PrivateRoute />}>
                    <Route path={`/admin/upload/image`} element={<UploadFile />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}