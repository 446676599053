import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
/* datas */
import { HostBackend } from '../HostBackend.js';
/* document */
import { Experiment } from '../../Pages/Public/Experiment.js';
import { NotFound } from '../../Pages/Public/NotFound.js';

export const RouteExperiment = (props) => {
    const navParks = props.navParks;
    const hosting = HostBackend();
    const [navExperiments, setNavExperiments] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const dataNavExperiments = await fetch(`${hosting}/nav/experiments`);
            const navExperiments = await dataNavExperiments.json();
            setNavExperiments(navExperiments);
        };
        fetchData();
    }, [hosting]);
    return (
        <BrowserRouter>
            <Routes>
                {navExperiments.map((el, i) => {
                    return (<Route path={el.route} element={<Experiment navParks={navParks} id={el.id} slug={el.slug} />} key={`c-${i}`} />)
                })}
                <Route path="*" element={<NotFound navParks={navParks} />} />
            </Routes>
        </BrowserRouter>
    )
}