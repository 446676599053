import { GetIconCode } from '../Functions/GetIconCode';
import { GetStringTotal } from '../Functions/GetStringTotal';

export const BlockLink = (props) => {
    switch (props.label) {
        case 'link-total':
            if (props.total > 0) {
                return (
                    <div className="title-link">
                        <div className="name">
                            <span>{props.total}</span>
                            {GetStringTotal(props.name, props.total)}
                        </div>
                    </div>
                )
            } else {
                return (
                    <></>
                )
            }
        case 'link-date':
            return (
                <a className="title-link" href={props.href} alt={props.name} title={props.name}>
                    <div className="code"><i className={GetIconCode('LK')}></i></div>
                    <div className="name">
                        <div>{props.name}</div>
                        <div>{props.date}</div>
                    </div>
                </a>
            )
        case 'link-true':
        case 'link-jumba-jookiba':
                return (
                <a className="title-link" href={props.href} alt={props.name} title={props.name}>
                    <div className="code"><i className={GetIconCode('LK')}></i></div>
                    <div className="name">{props.name}</div>
                </a>
            )
        default:
            return (
                <></>
            )
    }
}