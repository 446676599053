import { useParams } from 'react-router-dom';
import { HostBackend } from '../../../Routes/HostBackend.js';
import { Fields } from './Fields.js';

export const FormInsert = () => {
    const hosting = HostBackend();
    const { entity } = useParams();
    return (
        <form className="form" action={`${hosting}/admin/insert/${entity}`} method="POST">
            <fieldset>
                <legend>{`INSERT: ${entity}`}</legend>
                <Fields entity={entity} />
                <input className="button" type="submit" defaultValue="Insert" />
            </fieldset>
        </form>
    )
}