import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HostBackend } from '../HostBackend.js';
import { Univers } from '../../Pages/Public/Univers.js';
import { NotFound } from '../../Pages/Public/NotFound.js';
import { FilterParkAndUnivers } from '../../Components/Public/Filters/FilterParkAndUnivers.js';

export const RouteUnivers = () => {
    const hosting = HostBackend();
    const [navAttractions, setNavAttractions] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const dataNavAttractions = await fetch(`${hosting}/nav/attractions`);
            const navAttractions = await dataNavAttractions.json();
            setNavAttractions(navAttractions);
        };
        fetchData();
    }, [hosting]);
    const navParks = FilterParkAndUnivers(navAttractions);
    return (
        <BrowserRouter>
            <Routes>
                {navParks.map((el) => {
                    return (
                        el.univers.map((u, i) => {
                            return (<Route path={u.uroute} element={<Univers id={u.uid} par={navParks} att={navAttractions} />} key={`u-${i}`} />)
                        })
                    )
                })}
                <Route path="*" element={<NotFound navParks={navParks} />} />
            </Routes>
        </BrowserRouter>
    )
}