import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../../../Routes/HostBackend.js';
/* document */
import { Select } from '../../Form/Inputs/Select.js';
import { Text } from '../../Form/Inputs/Text.js';
import { Date } from '../../Form/Inputs/Date.js';
import { Hidden } from '../../Form/Inputs/Hidden.js';

export const Univers = (props) => {
    const hosting = HostBackend();
    const id_park = (props.el) ? props.el.id_park : '';
    const name = (props.el) ? props.el.name : '';
    const slug = (props.el) ? props.el.slug : '';
    const route = (props.el) ? props.el.route : '';
    const created = (props.el) ? props.el.created : '';
    const author = (props.el) ? props.el.author : '';
    const id_description = (props.el) ? props.el.id_description : '';
    const id = (props.el) ? props.el.id : '';
    const [parkChooser, setParkChooser] = useState([]);
    const [descriptionChooser, setDescriptionChooser] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const datasParks = await fetch(`${hosting}/admin/collection/park`);
            const parkChooser = await datasParks.json();
            setParkChooser(parkChooser);
            const datasDescription = await fetch(`${hosting}/admin/collection/describe_univers`);
            const descriptionChooser = await datasDescription.json();
            setDescriptionChooser(descriptionChooser);
        };
        fetchData();
    }, [hosting]);
    return (
        <>
            <Select name="id_park" chooser={parkChooser} value={id_park} />
            <Text name="name" value={name} />
            <Text name="slug" value={slug} />
            <Text name="route" value={route} />
            <Date name="created" value={created} />
            <Text name="author" value={author} />
            <Select name="id_description" chooser={descriptionChooser} value={id_description} />
            <Hidden name="id" value={id} />
        </>
    )
}