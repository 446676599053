import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../../../Routes/HostBackend.js';
import { Slugify } from '../../Functions/Slugify.js';
import { NameToArray } from '../../Functions/NameToArray.js';

export const SelectTheme = ({ type, value, onSelect, onDatas }) => {
    let datasTheme;
    const hosting = `${HostBackend()}/admin/upload`;
    const [chooserTheme, setChooserTheme] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            if (type.match(/attractions/)) datasTheme = await fetch(`${hosting}/categories`);
            if (type.match(/characters/)) datasTheme = await fetch(`${hosting}/origins`);
            if (type.match(/experiments/)) datasTheme = await fetch(`${hosting}/series`);
            if (type) {
                const chooserTheme = await datasTheme.json();
                setChooserTheme(chooserTheme);
            }
        };
        fetchData();
    }, [hosting, type]);
    const sendData = (id) => {
        let themeChooser = NameToArray(chooserTheme);
        return Slugify(themeChooser[id]);
    }
    return (
        <div className="row">
            <label className="label" htmlFor="theme">Theme</label>
            <select className="select" id="theme" name="theme" value={value} onChange={(e) => {
                onSelect(e.target.value);
                onDatas(sendData(e.target.value));
            }}>
                <option value="0">-- choose option --</option>
                {chooserTheme.map((el, i) => {
                    return (<option key={`i-${i}`} value={el.id}>{el.name}</option>)
                })}
            </select>
        </div>
    )
}