/* datas */
import { FilterExperiments } from '../Filters/FilterExperiments.js';

export const BlockExperiments = (props) => {
    const series = props.series;
    const experiments = props.experiments;
    return (
        <div className="load-experiments">
            {series.map((serie, i) => {
                let collection = FilterExperiments(experiments, serie.id);
                let title = serie.name;
                let total_serie = collection.length;
                return (
                    <div key={`b-${i}`} className="block-list">
                        <div className="title-list">
                            <div>{`SERIE ${i}00: ${title}`}</div>
                            <div>{`${total_serie} expériences`}</div>
                        </div>
                        <div className="listing">
                            {collection.map((item, i) => {
                                return (
                                    <div key={`i-${i}`} className="item">
                                        <div className="code">{item.number}</div>
                                        <div className="name">{item.name}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}