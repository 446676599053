/* route public */
import { RouteWebsocket } from './Public/RouteWebsocket.js';
import { RouteHome } from './Public/RouteHome.js';
import { RouteParks } from './Public/RouteParks.js';
import { RouteUnivers } from './Public/RouteUnivers.js';
import { RouteAttractions } from './Public/RouteAttractions.js';
import { RouteCategories } from './Public/RouteCategories.js';
import { RouteCategory } from './Public/RouteCategory.js';
import { RouteCharacters } from './Public/RouteCharacters.js';
import { RouteCharacter } from './Public/RouteCharacter.js';
import { RouteOrigins } from './Public/RouteOrigins.js';
import { RouteOrigin } from './Public/RouteOrigin.js';
import { RouteOutlinks } from './Public/RouteOutlinks.js';
import { RouteExperiments} from './Public/RouteExperiments.js';
import { RouteExperiment } from './Public/RouteExperiment.js';
/* route admin */
import { RouteLogin } from './Admin/RouteLogin.js';
import { RouteDatabase } from './Admin/RouteDatabase.js';
import { RouteCollection } from './Admin/RouteCollection.js';
import { RouteInsert } from './Admin/RouteInsert.js';
import { RouteUpdate } from './Admin/RouteUpdate.js';
import { RouteUpload } from './Admin/RouteUpload.js';

export const Router = (props) => {
    const navParks = props.navParks;
    const uri = window.location.href;
    if (uri.includes('/ws')) return (<RouteWebsocket navParks={navParks} />);
    /* admin */
    if (uri.includes('/admin/connexion')) return (<RouteLogin />);
    if (uri.includes('/admin/database/backup')) return (<RouteDatabase />);
    if (uri.includes('/admin/database/collection')) return (<RouteDatabase />);
    if (uri.includes('/admin/database/generate')) return (<RouteDatabase />);
    if (uri.includes('/admin/database/create')) return (<RouteDatabase />);
    if (uri.includes('/admin/entity/collection')) return (<RouteCollection />);
    if (uri.includes('/admin/entity/insert')) return (<RouteInsert />);
    if (uri.includes('/admin/entity/update')) return (<RouteUpdate />);
    if (uri.includes('/admin/upload/image')) return (<RouteUpload />);
    if (uri.includes('/admin/upload/uploading')) return (<RouteUpload />);
    /* public */
    if (uri.includes('/categories')) return(<RouteCategories navParks={navParks} />);
    if (uri.includes('/categorie')) return(<RouteCategory navParks={navParks} />);
    if (uri.includes('/films-et-series')) return(<RouteOrigins navParks={navParks} />);
    if (uri.includes('/origine')) return(<RouteOrigin navParks={navParks} />);
    if (uri.includes('/personnages')) return(<RouteCharacters navParks={navParks} />);
    if (uri.includes('/attraction')) return (<RouteAttractions navParks={navParks} />);
    if (uri.includes('/personnage')) return(<RouteCharacter navParks={navParks} />);
    if (uri.includes('/univers')) return (<RouteUnivers navParks={navParks} />);
    if (uri.includes('/park')) return (<RouteParks navParks={navParks} />);
    if (uri.includes('/experiences')) return(<RouteExperiments navParks={navParks} />);
    if (uri.includes('/experience')) return(<RouteExperiment navParks={navParks} />);
    if (uri.includes('/liens')) return(<RouteOutlinks navParks={navParks} />);
    if (uri.includes('/')) return (<RouteHome navParks={navParks} />);
}