export const GetBkgHeader = (uri) => {
    let bkgHeader = 'default-header';
    /* univers */
    if (uri.match(/univers/)) {
        if (uri.match(/adventureland/)) bkgHeader = 'adventureland';
        if (uri.match(/discoveryland/)) bkgHeader = 'discoveryland';
        if (uri.match(/fantasyland/)) bkgHeader = 'fantasyland';
        if (uri.match(/frontierland/)) bkgHeader = 'frontierland';
        if (uri.match(/main-street-usa/)) bkgHeader = 'main-street-usa';
        if (uri.match(/avengers-campus/)) bkgHeader = 'avengers-campus';
        if (uri.match(/front-lot/)) bkgHeader = 'front-lot';
        if (uri.match(/production-courtyard/)) bkgHeader = 'production-courtyard';
        if (uri.match(/toon-studio/)) bkgHeader = 'toon-studio';
        if (uri.match(/worlds-of-pixar/)) bkgHeader = 'worlds-of-pixar';
    }
    /* park */
    if (!uri.match(/univers/)) {
        if (uri.match(/park-disneyland/)) bkgHeader = 'park-park-disneyland';
        if (uri.match(/walt-disney-studios/)) bkgHeader = 'park-walt-disney-studios';
    }
    /* others */
    if (uri.match(/experience/)) bkgHeader = 'experiment';
    return bkgHeader;
}