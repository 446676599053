export const BlockEpisode = (props) => {
    const item = props.item;
    const episodes = item.episode && item.episode.includes(',') ? item.episode.split(',') : [item.episode];
    return (
        <div className="episode">
            <div className="episode-title">Episodes, Films et Jeux</div>
            <div className="note">Ce personnage est visible dans la liste<br/>des médias ci-dessous.</div>
            <ul className="episode-name">
            {episodes.map((e, i) => {
                return (<li className="episode-name" key={`e-${i}`}>{e}</li>)
            })}
            </ul>
        </div>
    )
}