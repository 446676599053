import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
import { CountDatas } from '../../Components/Public/Functions/CountDatas.js';
import { FilterOriginsType } from '../../Components/Public/Filters/FilterOriginsType..js';
import { FilterOriginsByType } from '../../Components/Public/Filters/FilterOriginsByType.js';
import { Slugify } from '../../Components/Admin/Functions/Slugify.js';
/* document */
import { Navigation } from '../../Components/Public/Document/Navigation.js';
import { Header } from '../../Components/Public/Document/Header.js';
import { Footer } from '../../Components/Public/Document/Footer.js';
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { BreadTags } from '../../Components/Public/Document/BreadTags.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockTitle } from '../../Components/Public/Blocks/BlockTitle.js';
import { BlockOutlink } from '../../Components/Public/Blocks/BlockOutlink.js';
import { BackTop } from '../../Components/Public/Document/BackTop.js';
import { ScrollTop } from '../../Components/Public/Functions/ScrollTop.js';

export const Origins = (props) => {
    const navParks = props.navParks;
    const hosting = HostBackend();
    const [origins, setOrigins] = useState([]);
    const [body, setBody] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const originsDatas = await fetch(`${hosting}/origins`);
            const origins = await originsDatas.json();
            setOrigins(origins);
            const bodyDatas = await fetch(`${hosting}/body/origins`);
            const body = await bodyDatas.json();
            setBody(body);
        };
        fetchData();
        ScrollTop();
    }, [hosting]);
    const total = props.ori.length;
    const tt_origin = CountDatas('origin', props.att);
    const tt_character = CountDatas('character', props.cha);
    const originsType = FilterOriginsType(origins);
    return (
        <div className="origins">
            <Navigation navParks={navParks} />
            <Header />
            <BreadCrumb />
            <section className="infos">
                <div className="content">
                    <BreadTags label="origins" datas={originsType} />
                    {originsType.map((el, i) => {
                        const originsByType = FilterOriginsByType(origins, el.otid);
                        const total = originsByType.length;
                        return (
                            <div className="origintype" key={`i-${i}`}>
                                <div className="title-list" id={Slugify(el.otname)}>{`${el.otname} (${total})`}</div>
                                <div className="list">
                                    {originsByType.map((item, i) => {
                                        return (
                                            <BlockLogo label="origins" item={item} tt_ori={tt_origin[item.oslug]} tt_car={tt_character[item.oslug]} key={`i-${i}`} />
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
                {body.map((item, i) => {
                    item['created'] = '1937-01-01';
                    item['author'] = 'Walt Disney Imagineering';
                    item['name'] = 'Films et\nSéries télévisées\ndes Studios Disney';
                    return (
                        <div className="description" key={`i-${i}`}>
                            <BlockTitle label="origins" item={item} total={total} />
                            <p>{item.description}</p>
                            <BlockOutlink item={item} />
                        </div>
                    )
                })}
            </section>
            <BackTop />
            <Footer />
        </div>
    )
}
