import { ButtonDelete } from './ButtonDelete.js';
import { ButtonUpdate } from './ButtonUpdate.js';

export const ButtonsTransaction = (props) => {
    return (
        <div className="block-buttons">
            <ButtonUpdate entity={props.entity} id={props.id} />
            <ButtonDelete entity={props.entity} id={props.id} />
        </div>
    )
}