export const Selectype = ({value, onSelect}) => {
    const chooserType = ['attractions', 'characters', 'experiments'];
    return (
        <div className="row">
            <label className="label" htmlFor="type">Type</label>
            <select className="select" id="type" name="type" value={value} onChange={(e) => onSelect(e.target.value)}>
                <option value="0">-- choose option --</option>
                {chooserType.map((name, i) => {
                    return (<option key={`i-${i}`} value={name}>{name}</option>)
                })}
            </select>
        </div>
    );
}