import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
import { LogoImgDefault } from '../../Files/Logos/LogoImgDefault.js';
/* document */
import { Navigation } from '../../Components/Public/Document/Navigation.js';
import { Header } from '../../Components/Public/Document/Header.js';
import { Footer } from '../../Components/Public/Document/Footer.js';
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { Carrousel } from '../../Components/Public/Carrousel/Carrousel.js';
import { BlockTitle } from '../../Components/Public/Blocks/BlockTitle.js';
import { BlockOutlink } from '../../Components/Public/Blocks/BlockOutlink.js';
import { GetIconCode } from '../../Components/Public/Functions/GetIconCode.js';
import { BackTop} from '../../Components/Public/Document/BackTop.js';
import { ScrollTop } from '../../Components/Public/Functions/ScrollTop.js';

export const Outlinks = (props) => {
    const navParks = props.navParks;
    const hosting = HostBackend();
    const [body, setBody] = useState([]);
    const [outlinks, setOutlinks] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const datasOutlinks = await fetch(`${hosting}/outlinks`);
            const outlinks = await datasOutlinks.json();
            setOutlinks(outlinks);
            const bodyDatas = await fetch(`${hosting}/body/outlinks`);
            const body = await bodyDatas.json();
            setBody(body);
        };
        fetchData();
        ScrollTop();
    }, [hosting]);
    const collection = [];
    const logoImgDefault = LogoImgDefault('img')
    collection.push({ images: logoImgDefault })
    const item = {
        created: '1992-04-12',
        author: 'Liens externes',
        name: 'Remerciements',
        link_name: 'Wikipedia',
        link_href: 'https://wikipedia.com',
    }
    const total = outlinks.length;
    const description = body[0] ? body[0].description : '';
    return (
        <div className="outlinks">
            <Navigation navParks={navParks} />
            <Header />
            <Carrousel label="outlinks" datas={collection} total={logoImgDefault.length} />
            <BreadCrumb />
            <section className="infos">
                <div className="bloc-infos">
                    <div className="list">
                        {outlinks.map((el, i) => {
                            return (
                                <a key={`o-${i}`} href={el.route} alt={el.name} target="_blank" rel="noreferrer" className="link">
                                    <div className="code"><i className={GetIconCode('LK')}></i></div>
                                    <div className="name">{`${el.name}`}</div>
                                </a>
                            )
                        })}

                    </div>
                </div>
                <div className="description">
                    <BlockTitle label="outlinks" item={item} total={total} />
                    <p>{description}</p>
                    <BlockOutlink item={item} />
                </div>
            </section>
            <BackTop />
            <Footer />
        </div>
    )
}