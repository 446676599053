import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
import { GetAllEntities } from '../../Components/Admin/Functions/GetAllEntities.js';
import { EqualizeFilesMysql } from '../../Components/Admin/Functions/EqualizeFilesMysql.js';
/* document */
import { Navigation } from '../../Components/Admin/Document/Navigation.js';
import { Footer } from '../../Components/Public/Document/Footer.js';
import { BackTop } from '../../Components/Public/Document/BackTop.js';
import { THead } from '../../Components/Admin/Collection/THead.js';
import { Tables } from '../../Components/Admin/Collection/Tables.js';

export const DatabaseCollection = () => {
    const hash = sessionStorage.getItem('token');
    const files = [].concat(GetAllEntities()[0], GetAllEntities()[1], GetAllEntities()[2]).sort();
    const hosting = HostBackend();
    const [tables, setTables] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const dataTables = await fetch(`${hosting}/admin/database/show`);
            const tables = await dataTables.json();
            setTables(tables);
        };
        fetchData();
    }, [hosting]);
    const compare = EqualizeFilesMysql(files, tables);
    if (hash) {
        return (
            <div className="collection">
                <Navigation />
                <div className="admin-list">
                    <div className="title">
                        <div>{`ADMIN: files (${files.length}) - mysql (${tables.length})`}</div>
                    </div>
                    <THead entity="database" />
                    <Tables compare={compare} />
                </div>
                <BackTop />
                <Footer />
            </div>
        )
    } else {
        return (<></>)
    }
}