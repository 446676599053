export const GetDateLong = (string) => {
    const date = new Date(string);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    const date_format = date.toLocaleDateString('fr', options);
    return date_format;
}
export const GetDateShort = () => {
    const date = new Date();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
    const brut = date.toLocaleDateString('fr', options).split('/');
    const date_format = `${brut[2]}-${brut[1]}-${brut[0]}`;
    return date_format;
}
