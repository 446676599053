import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
import { ScrollTop } from '../../Components/Public/Functions/ScrollTop.js';
/* document */
import { Navigation } from '../../Components/Admin/Document/Navigation.js';
import { Footer } from '../../Components/Public/Document/Footer.js';
import { BackTop } from '../../Components/Public/Document/BackTop.js';
import { THead } from '../../Components/Admin/Collection/THead.js';
import { Entities } from '../../Components/Admin/Collection/Entities.js';
import { Attributes } from '../../Components/Admin/Collection/Attributes.js';
import { Describes } from '../../Components/Admin/Collection/Describes.js';
import { GetEntitySelector } from '../../Components/Admin/Functions/GetEntitySelector.js';

export const Collection = () => {
    const hash = sessionStorage.getItem('token');
    let { entity } = useParams();
    let { sort } = useParams();
    let selector = GetEntitySelector(entity);
    let sorter = sort ? `/sortby?sort=${sort}` : '';
    const hosting = HostBackend();
    const [chooser, setChooser] = useState([]);
    const [collection, setCollection] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const dataChooser = await fetch(`${hosting}/admin/chooser/${selector}`);
            const chooser = await dataChooser.json();
            setChooser(chooser);
            const dataCollection = await fetch(`${hosting}/admin/collection/${entity}${sorter}`);
            const collection = await dataCollection.json();
            setCollection(collection);
        };
        fetchData();
        ScrollTop();
    }, [hosting, entity, sorter, selector, sort]);
    if (hash) {
        return (
            <div className="collection">
                <Navigation entity={entity} chooser={chooser} />
                <div className="admin-list">
                    <div className="title">
                        <div>{`ADMIN: ${entity} (${collection.length})`}</div>
                    </div>
                    <THead entity={entity} />
                    <Entities entity={entity} datas={collection} />
                    <Attributes entity={entity} datas={collection} />
                    <Describes entity={entity} datas={collection} />
                </div>
                <BackTop />
                <Footer />
            </div>
        )
    } else {
        return(<></>)
    }
}