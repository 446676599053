import { Slugify } from '../../Admin/Functions/Slugify.js';

export const BreadTags = (props) => {
    let name;
    const datas = props.datas;
    return (
        <div className="breadtags">
            {datas.map((el, i) => {
                if (props.label === 'origins') name = el.otname;
                if (props.label === 'experiments') name = `Série ${i}00`;
                return (
                    <a className="tag" href={`#${Slugify(name)}`} alt={name} key={`i-${i}`}>{name}</a>
                )
            })}
        </div>
    )

}