import { GetStringTotal } from "../Functions/GetStringTotal";

export const BlockStringTotal = (props) => {
    const total = props.total;
    let string = 'attraction';
    if (props.label.match(/origins/)) string = 'origine';
    if (props.label.match(/character/)) string = 'personnage';
    if (props.label.match(/outlink/)) string = 'lien';
    if (props.label.match(/experiment/)) string = 'expérience';
    return (
        <div className="title-total-label">
            <span>{total}</span> {GetStringTotal(string, total)}
        </div>
    );
}